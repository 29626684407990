import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
} from '@mui/material';
import ConditionTree from './ConditionTree';

const GroupDialog = ({ isOpen, onClose, group, setGroup, onSave, isEditing, fields }) => {
  const handleRulesChange = (e) => {
    const { name, value, type, checked } = e.target;
    setGroup(prevGroup => ({
      ...prevGroup,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleConditionsChange = (newConditions) => {
    setGroup(prevGroup => ({
      ...prevGroup,
      conditions: newConditions,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEditing ? 'Modifica Gruppo' : 'Aggiungi Nuovo Gruppo'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Group Label"
          name="label"
          type="text"
          fullWidth
          value={group.label}
          onChange={handleRulesChange}
        />
        <TextField
          margin="dense"
          label="Group Description"
          name="description"
          type="text"
          fullWidth
          multiline
          rows={2}
          value={group.description}
          onChange={handleRulesChange}
        />
       
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onSave}>{isEditing ? 'Update' : 'Add'} Group</Button>
      </DialogActions>
    </Dialog>
  );
};

GroupDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  group: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    conditions: PropTypes.array,
  }).isRequired,
  setGroup: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  fields: PropTypes.array.isRequired,
};

export default GroupDialog;