import axios from 'axios';

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/ai_app';
const TOKEN = localStorage.getItem("token");

export const fetchAI = async (companyId) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/ai/${companyId}/`, {
            headers: { Authorization: `Bearer ${TOKEN}` }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // AI doesn't exist for this company
            return null;
        }
        throw error;
    }
};

export const createOrUpdateAI = async (companyId, aiData) => {
    try {
        const response = await axios.put(`${API_BASE_URL}/ai/${companyId}/`, aiData, {
            headers: { Authorization: `Bearer ${TOKEN}` }
        });
        return response.data;
    } catch (error) {
        if (error.response && error.response.status === 404) {
            // AI doesn't exist, so create a new one
            const createResponse = await axios.post(`${API_BASE_URL}/ai/`, {
                ...aiData,
                company: companyId
            }, {
                headers: { Authorization: `Bearer ${TOKEN}` }
            });
            return createResponse.data;
        }
        throw error;
    }
};