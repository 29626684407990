import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { 
    Box, Typography, TextField, Button, CssBaseline, Grid, 
    createTheme, ThemeProvider, Dialog, DialogActions, 
    DialogContent, DialogContentText, DialogTitle,
    Select, MenuItem, FormControl, InputLabel
} from '@mui/material';
import { itIT, itIT as pickersItIT, itIT as coreItIT } from '@mui/material/locale';
import { fetchUsers, createUser, updateUser, deleteUser, fetchCurrentUserRole, updateUserPassword } from '../api/user';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme(
    {
        palette: {
            primary: { main: '#4361EE' },
            secondary: { main: '#4895EF' },
            third: { main: '#4CC9F0' },
            background: { main: 'rgba(163, 174, 208, 0.05)' },
        },
        components: {
            MuiButton: {
                styleOverrides: {
                    root: { boxShadow: 'none' },
                },
            }
        }
    }, 
    itIT, pickersItIT, coreItIT
);

const roleHierarchy = {
    'superadmin': ['admin','staff', 'external_staff', 'client'],
    'admin': ['admin','staff', 'external_staff', 'client'],
    'staff': ['external_staff', 'client'],
    'external_staff': ['client'],
    'client': []
};

const PaginaUtenti = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const [availableRoles, setAvailableRoles] = useState([]);
    const [currentUserRole, setCurrentUserRole] = useState('');
    const [currentUserId, setCurrentUserId] = useState(null);

    const [error, setError] = useState('');
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const { userRole, appAccess } = useAuth(); 
    const permissions = appAccess['user_management'] || {};
    const canView = permissions.can_view || userRole === 'superadmin';
    const canCreate = permissions.can_create || userRole === 'superadmin';
    const canEdit = permissions.can_edit || userRole === 'superadmin';
    const canDelete = permissions.can_delete || userRole === 'superadmin';

    useEffect(() => {
        const fetchData = async () => {
            if (!canView) return;
            try {
                const [usersData, userRole] = await Promise.all([
                    fetchUsers(),
                    fetchCurrentUserRole()
                ]);
                setRows(usersData);
                setCurrentUserRole(userRole.role);
                setCurrentUserId(userRole.id);
                setAvailableRoles(roleHierarchy[userRole.role] || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [canView]);


    console.log("usersData", usersData)
    const handleAdd = () => {
        if (!canCreate) return;
        setCurrentRow({ id: null, first_name: '', last_name: '', phone: '', email: '', role: '' });
        setOpen(true);
        setError('');
    };

    const handleEdit = (row) => {
        if (!canEdit) return;
        setCurrentRow(row);
        setOpen(true);
        setError('');
    };

    const handleDelete = async (id) => {
        if (!canDelete) return;
        try {
            await deleteUser(id);
            setRows(rows.filter((row) => row.id !== id));
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentRow(null);
        setError('');
    };

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const generateRandomUsername = () => {
        return 'user_' + Math.random().toString(36).substr(2, 8);
    };

    const handlePasswordSave = async () => {
        if (newPassword !== confirmPassword) {
            setError('Le password non corrispondono');
            return;
        }

        try {
            await updateUserPassword(currentUserId, newPassword);
            handlePasswordDialogClose();
        } catch (error) {
            console.error('Errore nell\'aggiornamento della password:', error);
            setError('Errore nell\'aggiornamento della password. Per favore riprova.');
        }
    };

    const handleSave = async () => {
        if (!validateEmail(currentRow.email)) {
            setError('Invalid email address');
            return;
        }

        try {
            const username = currentRow.id ? currentRow.username : generateRandomUsername();
            const password = currentRow.id ? currentRow.password : Math.random().toString(36).substr(2, 8);
            const userData = {
                ...currentRow,
                username,
                password,
            };

            if (currentRow.id) {
                const updatedUser = await updateUser(currentRow.id, userData);
                setRows(rows.map((row) => (row.id === updatedUser.id ? updatedUser : row)));
            } else {
                const newUser = await createUser(userData);
                setRows([...rows, newUser]);
            }
            handleClose();
        } catch (error) {
            console.error('Error saving user:', error);
            setError('Error saving user. Please try again.');
        }
    };

    const handleChangePassword = (row) => {
        setCurrentRow(row);
        setPasswordDialogOpen(true);
        setNewPassword('');
        setConfirmPassword('');
        setError('');
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        setNewPassword('');
        setConfirmPassword('');
        setError('');
    };

    const columns = [
        { field: 'first_name', headerName: 'Nome', width: 150 },
        { field: 'last_name', headerName: 'Cognome', width: 150 },
        { field: 'phone', headerName: 'Telefono', width: 150 },
        { field: 'email', headerName: 'Email', width: 200 },
        { field: 'role', headerName: 'Ruolo', width: 150 },
        { field: 'username', headerName: 'Username', width: 150 },
        { 
            field: 'primary_company', 
            headerName: 'Azienda', 
            width: 200,
            valueGetter: (params) => {
                if (params.row.primary_company) {
                    return params.row.primary_company.name || '';
                }
                return '';
            }
        },
        {
            field: 'actions',
            headerName: 'Azioni',
            width: 280,
            renderCell: (params) => (
                <strong>
                    {canEdit && (
                        <Button size="small" variant="contained" onClick={() => handleEdit(params.row)} style={{ borderRadius: '50%', minWidth: 40, height: 40, backgroundColor: 'rgb(244, 247, 254)' }}>
                            <svg viewBox="0 0 24 24" fill="none"  xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M21.1213 2.70705C19.9497 1.53548 18.0503 1.53547 16.8787 2.70705L15.1989 4.38685L7.29289 12.2928C7.16473 12.421 7.07382 12.5816 7.02986 12.7574L6.02986 16.7574C5.94466 17.0982 6.04451 17.4587 6.29289 17.707C6.54127 17.9554 6.90176 18.0553 7.24254 17.9701L11.2425 16.9701C11.4184 16.9261 11.5789 16.8352 11.7071 16.707L19.5556 8.85857L21.2929 7.12126C22.4645 5.94969 22.4645 4.05019 21.2929 2.87862L21.1213 2.70705ZM18.2929 4.12126C18.6834 3.73074 19.3166 3.73074 19.7071 4.12126L19.8787 4.29283C20.2692 4.68336 20.2692 5.31653 19.8787 5.70705L18.8622 6.72357L17.3068 5.10738L18.2929 4.12126ZM15.8923 6.52185L17.4477 8.13804L10.4888 15.097L8.37437 15.6256L8.90296 13.5112L15.8923 6.52185ZM4 7.99994C4 7.44766 4.44772 6.99994 5 6.99994H10C10.5523 6.99994 11 6.55223 11 5.99994C11 5.44766 10.5523 4.99994 10 4.99994H5C3.34315 4.99994 2 6.34309 2 7.99994V18.9999C2 20.6568 3.34315 21.9999 5 21.9999H16C17.6569 21.9999 19 20.6568 19 18.9999V13.9999C19 13.4477 18.5523 12.9999 18 12.9999C17.4477 12.9999 17 13.4477 17 13.9999V18.9999C17 19.5522 16.5523 19.9999 16 19.9999H5C4.44772 19.9999 4 19.5522 4 18.9999V7.99994Z" fill="#4895EF"></path> </g></svg>
                        </Button>
                    )}
                    
                    {canDelete && (
                        <Button size="small" variant="contained" style={{ marginLeft: 16, borderRadius: '50%', minWidth: 40, height: 40, backgroundColor: 'rgb(244, 247, 254)' }} onClick={() => handleDelete(params.row.id)} >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" width="20" height="20"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M10 11V17" stroke="#4895EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M14 11V17" stroke="#4895EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M4 7H20" stroke="#4895EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M6 7H12H18V18C18 19.6569 16.6569 21 15 21H9C7.34315 21 6 19.6569 6 18V7Z" stroke="#4895EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z" stroke="#4895EF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>
                        </Button>
                    )}
                </strong>
            ),
        },
    ];

    if (!canView) {
        return (
            <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
                Non hai il permesso di visualizzare questa pagina.
            </Typography>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ minHeight: '100vh', backgroundColor: theme.palette.background.main }}>
                <Grid container spacing={2} component="main" sx={{ p: { xs: 4, md: 10 }, mt: { xs: 10, md: 0 } }}>
                    <Box sx={{ mb: { xs: 5, md: 10 } }}>
                        <Typography variant="h2" sx={{ display: { xs: 'none', md: 'block' } }}> Utenti </Typography>
                        <Typography variant="h7" sx={{ pl: 1, display: { xs: 'none', md: 'block' } }}>
                            <b style={{ color: '#2BD900' }}>{rows.length}</b> Utenti totali
                        </Typography>
                    </Box>

                    <Grid container>
                        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 5}}>
                            <Box>
                                {canCreate && (
                                    <Button variant="outlined" color="primary" onClick={handleAdd} sx={{ borderRadius: '20px' }}>
                                        + Aggiungi Utente
                                    </Button>
                                )}
                            
                                <Button variant="contained" color="primary" onClick={() => handleChangePassword(currentRow)} sx={{ borderRadius: '20px', ml: 2 }}>
                                    Cambia la tua Password
                                </Button>
                            </Box>
                            <DataGrid 
                                rows={rows} 
                                columns={columns} 
                                pageSize={5} 
                                checkboxSelection 
                                sx={{ 
                                    borderRadius: '15px', 
                                    backgroundColor: '#ffffff', 
                                    border: 'none', 
                                    '& .MuiDataGrid-cell': {border: 'none'}, 
                                    '& .MuiDataGrid-columnHeaders': {border: 'none'},
                                    '& .MuiDataGrid-footerContainer': {border: 'none'}, 
                                    '& .MuiDataGrid-columnHeaderRow': {border: 'none', borderTopLeftRadius: '15px', borderTopRightRadius: '15px'}  
                                }} 
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentRow?.id ? 'Modifica Utente' : 'Aggiungi Utente'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Compila i campi sottostanti per {currentRow?.id ? 'modificare' : 'aggiungere'} un utente.
                    </DialogContentText>
                    {error && <Typography color="error">{error}</Typography>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nome"
                        type="text"
                        fullWidth
                        value={currentRow?.first_name || ''}
                        onChange={(e) => setCurrentRow({ ...currentRow, first_name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Cognome"
                        type="text"
                        fullWidth
                        value={currentRow?.last_name || ''}
                        onChange={(e) => setCurrentRow({ ...currentRow, last_name: e.target.value })}
                    />
                    <TextField
                        margin="dense"
                        label="Telefono"
                        type="text"
                        fullWidth
                        hidden={true}
                        value={currentRow?.phone || ''}
                        onChange={(e) => setCurrentRow({ ...currentRow, phone: e.target.value })}
                        />
                    <TextField
                        margin="dense"
                        label="Email"
                        type="email"
                        fullWidth
                        value={currentRow?.email || ''}
                        onChange={(e) => setCurrentRow({ ...currentRow, email: e.target.value })}
                    />
                    <FormControl fullWidth margin="dense">
                        <InputLabel id="role-label">Ruolo</InputLabel>
                        <Select
                            labelId="role-label"
                            value={currentRow?.role || ''}
                            label="Ruolo"
                            onChange={(e) => setCurrentRow({ ...currentRow, role: e.target.value })}
                        >
                            {availableRoles.map((role) => (
                                <MenuItem key={role} value={role}>
                                    {role.charAt(0).toUpperCase() + role.slice(1).replace('_', ' ')}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog open={passwordDialogOpen} onClose={handlePasswordDialogClose}>
                <DialogTitle>Cambia la tua Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Inserisci la nuova password per l'utente {currentRow?.first_name} {currentRow?.last_name}.
                    </DialogContentText>
                    {error && <Typography color="error">{error}</Typography>}
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Nuova Password"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        label="Conferma Nuova Password"
                        type="password"
                        fullWidth
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handlePasswordDialogClose} color="primary">
                        Annulla
                    </Button>
                    <Button onClick={handlePasswordSave} color="primary">
                        Salva
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
};

export default PaginaUtenti;