import React from 'react';
import { AppBar, Toolbar, IconButton, Box, Avatar, Badge, Menu, MenuItem, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { Bell, HelpCircle, User, LogOut, Sun, Moon, Menu as MenuIcon, X } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const Header = ({ toggleSidebar, isSidebarOpen, darkMode, toggleDarkMode }) => {
  const { logoutUser, user } = useAuth();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
  const handleMenuClose = () => setAnchorEl(null);

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
        color: darkMode ? '#FFFFFF' : '#333333',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        height: { xs: 64, md: 80 },
        transition: 'all 0.3s ease',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between', height: '100%' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            color="inherit"
            aria-label="toggle sidebar"
            onClick={toggleSidebar}
            edge="start"
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={isSidebarOpen ? 'open' : 'closed'}
                initial={{ opacity: 0, rotate: -180 }}
                animate={{ opacity: 1, rotate: 0 }}
                exit={{ opacity: 0, rotate: 180 }}
                transition={{ duration: 0.3 }}
              >
                {isSidebarOpen ? <X size={24} /> : <MenuIcon size={24} />}
              </motion.div>
            </AnimatePresence>
          </IconButton>
          <motion.img 
            src={darkMode ? "/AitomotiveLab_White.svg" : "/AitomotiveLab.svg"}
            alt="AITomotive Logo" 
            style={{ height: isMobile ? '30px' : '40px', maxWidth: isMobile ? '120px' : '160px' }}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 400, damping: 10 }}
          />
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, md: 2 } }}>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Tooltip title={darkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}>
              <IconButton color="inherit" onClick={toggleDarkMode}>
                <AnimatePresence mode="wait" initial={false}>
                  <motion.div
                    key={darkMode ? 'dark' : 'light'}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 20, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {darkMode ? <Sun size={24} /> : <Moon size={24} />}
                  </motion.div>
                </AnimatePresence>
              </IconButton>
            </Tooltip>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Tooltip title="Notifications">
              <IconButton color="inherit">
                <Badge badgeContent={3} color="error">
                  <Bell size={24} />
                </Badge>
              </IconButton>
            </Tooltip>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Tooltip title="Help">
              <IconButton color="inherit">
                <HelpCircle size={24} />
              </IconButton>
            </Tooltip>
          </motion.div>
          <motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
            <Tooltip title="User Menu">
              <IconButton onClick={handleMenuOpen} sx={{ p: 0 }}>
                <Avatar
                  alt={user?.name || 'User'}
                  src={user?.avatar}
                  sx={{
                    width: 40,
                    height: 40,
                    border: '2px solid',
                    borderColor: darkMode ? '#4CC9F0' : '#4361EE',
                  }}
                />
              </IconButton>
            </Tooltip>
          </motion.div>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
                color: darkMode ? '#FFFFFF' : '#333333',
                border: `1px solid ${darkMode ? '#ffffff20' : '#00000020'}`,
                '& .MuiMenuItem-root': {
                  transition: 'background-color 0.3s',
                  '&:hover': {
                    backgroundColor: darkMode ? '#ffffff20' : '#00000010',
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            <MenuItem onClick={handleMenuClose}>
              <User size={16} style={{ marginRight: 8 }} />
              Profile
            </MenuItem>
            <MenuItem onClick={() => { handleMenuClose(); logoutUser(); }}>
              <LogOut size={16} style={{ marginRight: 8 }} />
              Logout
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;