import React, { useState, useEffect, useCallback } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, List, ListItem, ListItemText, Box, CircularProgress, Typography, Autocomplete } from '@mui/material';
import moment from 'moment';
import debounce from 'lodash.debounce';

const BookingDialog = ({ open, availableSlots, selectedSlot, onSlotSelect, onClose, onSubmit, fetchClients }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [carModel, setCarModel] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState({});
  const [notes, setNotes] = useState('');

  // Debounced function for fetching clients
  const debouncedFetchClients = useCallback(
    debounce(async (query) => {
      if (query.trim() === '') {
        setClients([]);
        return;
      }
      setIsSearching(true);
      try {
        const fetchedClients = await fetchClients(query);
        setClients(Array.isArray(fetchedClients.results) ? fetchedClients.results : []);
      } catch (error) {
        console.error('Error fetching clients:', error);
      } finally {
        setIsSearching(false);
      }
    }, 300),
    [fetchClients]
  );

  useEffect(() => {
    debouncedFetchClients(searchQuery);
  }, [searchQuery, debouncedFetchClients]);

  const handleSubmit = () => {
    const additionalInformationArray = Object.keys(additionalInfo).map((key) => ({
      add_inf: key,
      description: additionalInfo[key],
    }));

    onSubmit({
      clientId: selectedClient?.id,
      slot: selectedSlot,
      carModel,
      additionalInfo: additionalInformationArray,
      notes,
    });
  };

  const handleAdditionalInfoChange = (id, value) => {
    setAdditionalInfo((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select a Client and Available Slot</DialogTitle>
      <DialogContent>
        {/* Client Search with Autocomplete */}
        <Box mb={2}>
          <Autocomplete
            value={selectedClient}
            onChange={(event, newValue) => {
              setSelectedClient(newValue);
            }}
            loading={isSearching}
            options={clients}
            getOptionLabel={(option) => 
              `${option.first_name} ${option.last_name} (${option.email})`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Clients"
                fullWidth
                margin="normal"
                onChange={(e) => setSearchQuery(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {isSearching ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props}>
                <Typography>
                  {option.first_name} {option.last_name} ({option.email})
                </Typography>
              </li>
            )}
            filterOptions={(x) => x} // Disable built-in filtering
            noOptionsText={searchQuery.trim() === '' ? 'Start typing to search' : 'No clients found'}
          />
        </Box>

        {/* Car Model Field */}
        <Box mb={2}>
          <TextField
            label="Car Model"
            value={carModel}
            onChange={(e) => setCarModel(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>

        {/* Notes Field */}
        <Box mb={2}>
          <TextField
            label="Notes"
            multiline
            rows={3}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            margin="normal"
          />
        </Box>

        {/* Additional Information Fields */}
        {availableSlots?.additional_information?.map((info, index) => (
          <Box key={index} mb={2}>
            <Typography variant="h6">{info.name}</Typography>
            <TextField
              fullWidth
              margin="normal"
              placeholder={info.description}
              value={additionalInfo[info.id] || ''}
              onChange={(e) => handleAdditionalInfoChange(info.id, e.target.value)}
            />
          </Box>
        ))}

        {/* Available Slots */}
        <List>
          {availableSlots?.available_slots && availableSlots.available_slots.length > 0 ? (
            availableSlots.available_slots.map((slot, index) => (
              <ListItem 
                button 
                key={index} 
                onClick={() => onSlotSelect(slot)}
                selected={selectedSlot === slot}
              >
                <ListItemText 
                  primary={`${moment(slot.start_time).format('MMMM D, YYYY')}`} 
                  secondary={`${moment(slot.start_time).format('h:mm A')} - ${moment(slot.end_time).format('h:mm A')} (${slot.available_slots} slots)`} 
                />
              </ListItem>
            ))
          ) : (
            <ListItem>
              <ListItemText primary="No available slots" />
            </ListItem>
          )}
        </List>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          variant="contained"
          disabled={!selectedClient || !selectedSlot || !carModel}
        >
          Book Appointment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
