import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Alert, Button, TextField, InputAdornment, IconButton, Typography, useMediaQuery } from '@mui/material';
import { Visibility, VisibilityOff, LockOutlined, PersonOutline } from '@mui/icons-material';
import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../components/contexts/AuthContext';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('BG_Aitomotivelab_nologo.jpg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.2);
    z-index: -1;
  }
`;

const LoginContainer = styled(motion.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  padding: 40px;
  width: 90%;
  max-width: 400px;
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  border: 1px solid rgba(255, 255, 255, 0.18);

  @media (max-width: 600px) {
    padding: 30px;
    width: 95%;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
`;

const Logo = styled.img`
  width: 180px;
  filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.5));
  
  @media (max-width: 600px) {
    width: 150px;
  }
`;

const ChatMessage = styled(Typography)`
  background-color: rgba(230, 243, 255, 0.1);
  color: #ffffff;
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 25px;
  text-align: center;
  font-weight: 300;
`;

const StyledTextField = styled(TextField)`
  margin-bottom: 20px;
  .MuiOutlinedInput-root {
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    &:hover fieldset {
      border-color: #00a8e8;
    }
  }
  .MuiInputLabel-root, .MuiOutlinedInput-input {
    color: #ffffff;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.3);
  }
`;

const LoginButton = styled(Button)`
  background: linear-gradient(45deg, #00a8e8 30%, #00e4d0 90%);
  border-radius: 10px;
  color: white;
  height: 48px;
  padding: 0 30px;
  box-shadow: 0 3px 5px 2px rgba(0, 168, 232, .3);
  transition: all 0.3s ease-in-out;
  &:hover {
    background: linear-gradient(45deg, #00e4d0 30%, #00a8e8 90%);
    transform: translateY(-2px);
  }
`;

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const { loginUser } = useAuth();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const validateForm = () => {
    let tempErrors = {};
    tempErrors.username = username ? "" : "Username is required";
    tempErrors.password = password ? "" : "Password is required";
    setErrors(tempErrors);
    return Object.values(tempErrors).every(x => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        await loginUser(username, password);
        navigate('/');
      } catch (error) {
        console.error('Login failed:', error);
        setErrors({ ...errors, general: 'Invalid username or password. Please try again.' });
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <PageContainer>
      <LoginContainer
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Header>
          <Logo src="https://aitomotivelab.com/images/logo/AitomotiveLab_White.svg" alt="AitomotiveLab Logo" />
        </Header>
        
        <ChatMessage variant="body1">
          Welcome to AitomotiveLab! Enter your credentials to access your account.
        </ChatMessage>
        
        {errors.general && <Alert severity="error" style={{ marginBottom: '20px', backgroundColor: 'rgba(211, 47, 47, 0.1)', color: '#ffffff' }}>{errors.general}</Alert>}
        
        <form onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            label="Username"
            variant="outlined"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            error={!!errors.username}
            helperText={errors.username}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <PersonOutline style={{ color: '#ffffff' }} />
                </InputAdornment>
              ),
            }}
          />
          <StyledTextField
            fullWidth
            label="Password"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            error={!!errors.password}
            helperText={errors.password}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOutlined style={{ color: '#ffffff' }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end" style={{ color: '#ffffff' }}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <LoginButton
            fullWidth
            type="submit"
            variant="contained"
          >
            Log In
          </LoginButton>
        </form>
      </LoginContainer>
    </PageContainer>
  );
};

export default LoginPage;