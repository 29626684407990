import React from 'react';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import ConditionNode from './ConditionNode';

const logicalOperators = [
  { value: 'AND', label: 'AND' },
  { value: 'OR', label: 'OR' },
];

const ConditionTree = ({ tree = [], fields = [], pages = [], onTreeChange, showNextPage = false, showApiCondition = false }) => {
  const handleNodeChange = (index, updatedNode) => {
    const newTree = [...tree];
    newTree[index] = updatedNode;
    onTreeChange(newTree);
  };

  const handleDeleteNode = (index) => {
    const newTree = tree.filter((_, idx) => idx !== index);
    onTreeChange(newTree);
  };

  const handleAddNode = (index) => {
    const newTree = [...tree];
    newTree.splice(index + 1, 0, { fieldId: '', type: 'equals', value: '', next_page_id: '', apiEndpoint: '', children: [] });
    onTreeChange(newTree);
  };

  const handleLogicalOperatorChange = (index, value) => {
    const newTree = [...tree];
    newTree[index].logicalOperator = value;
    onTreeChange(newTree);
  };

  return (
    <Box>
      {tree.map((node, index) => (
        <Box key={index}>
          <ConditionNode
            node={node}
            fields={fields}
            pages={pages}
            onChange={(updatedNode) => handleNodeChange(index, updatedNode)}
            onDelete={() => handleDeleteNode(index)}
            onAdd={() => handleAddNode(index)}
            showNextPage={showNextPage}
            showApiCondition={showApiCondition}
          />
          {index < tree.length - 1 && (
            <FormControl fullWidth margin="dense">
              <InputLabel>Logical Operator</InputLabel>
              <Select
                value={node.logicalOperator || 'AND'}
                onChange={(e) => handleLogicalOperatorChange(index, e.target.value)}
              >
                {logicalOperators.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {node.children && node.children.length > 0 && (
            <Box sx={{ borderLeft: '1px solid #ddd', ml: 2, pl: 2 }}>
              <ConditionTree
                tree={node.children}
                fields={fields}
                pages={pages}
                onTreeChange={(updatedChildren) => handleNodeChange(index, { ...node, children: updatedChildren })}
                showNextPage={showNextPage}
                showApiCondition={showApiCondition}
              />
            </Box>
          )}
        </Box>
      ))}
      <Button onClick={() => onTreeChange([...tree, { fieldId: '', type: 'equals', value: '', next_page_id: '', apiEndpoint: '', children: [] }])}>
        Add Condition
      </Button>
    </Box>
  );
};

export default ConditionTree;