import React, { useState, useEffect, useRef } from 'react';
import { 
  TextField, Box, Typography, Grid, Dialog,
  DialogTitle, DialogContent, IconButton, Tooltip, Zoom,
  Popover
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Send as SendIcon, Close as CloseIcon, AttachFile as AttachIcon, EmojiEmotions, Assignment as AssignmentIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import EmojiPicker from 'emoji-picker-react';
import CarSearchPage from './CarSearchPage';
import { CarAttachmentList, SendButton } from './SendComponents';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const SendMessageForm = ({ onSendMessage, unsentMessage }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [message, setMessage] = useState('');
  const [attachedCars, setAttachedCars] = useState([]);
  const [isCarSearchOpen, setIsCarSearchOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [includeRegistrationForm, setIncludeRegistrationForm] = useState(false);
  const [emojiAnchorEl, setEmojiAnchorEl] = useState(null);
  const [textFieldHeight, setTextFieldHeight] = useState('auto');
  const textFieldRef = useRef(null);

  useEffect(() => {
    if (unsentMessage && unsentMessage.sent === false) {
      setMessage(unsentMessage.bot_message || '');
      setAttachedCars(unsentMessage.car_stocks || []);
    }
  }, [unsentMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValidMessage()) {
      setIsSending(true);
      await onSendMessage({
        text: message,
        cars: attachedCars,
        include_registration_form: includeRegistrationForm
      });
      setMessage('');
      setAttachedCars([]);
      setIsExpanded(false);
      setIsSending(false);
      setIncludeRegistrationForm(false);
      
      // Reset the textarea height
      if (textFieldRef.current) {
        const textarea = textFieldRef.current.querySelector('textarea');
        if (textarea) {
          textarea.style.height = '24px'; // Reset to initial height
        }
      }
    }
  };

  const handleTextChange = (e) => {
    setMessage(e.target.value);
  };

  const isValidMessage = () => {
    if (!message.trim()) {
      return false; // Empty messages are not allowed
    }
    if (attachedCars.length > 0 || includeRegistrationForm) {
      return message.trim().length > 0; // Cars and registration form require text
    }
    return true; // Text alone is valid
  };

  const handleOpenCarSearch = () => setIsCarSearchOpen(true);
  const handleCloseCarSearch = () => setIsCarSearchOpen(false);
  const handleSelectCars = (selectedCars) => {
    setAttachedCars(selectedCars);
    setIncludeRegistrationForm(false);
    handleCloseCarSearch();
  };
  const handleRemoveCar = (vehicleid) => {
    setAttachedCars(attachedCars.filter(car => car.vehicleid !== vehicleid));
  };

  const toggleRegistrationForm = () => {
    if (attachedCars.length === 0) {
      setIncludeRegistrationForm(!includeRegistrationForm);
    }
  };

  const isSubmitDisabled = () => {
    return !isValidMessage() || isSending;
  };

  const handleOpenEmojiPicker = (event) => {
    setEmojiAnchorEl(event.currentTarget);
  };

  const handleCloseEmojiPicker = () => {
    setEmojiAnchorEl(null);
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevMessage) => prevMessage + emojiObject.emoji);
  };

  const updateTextFieldHeight = () => {
    if (isMobile && textFieldRef.current) {
      const textarea = textFieldRef.current.querySelector('textarea');
      if (textarea) {
        // Set a fixed minimum height instead of dynamically adjusting
        textarea.style.height = '56px';
        textarea.style.maxHeight = '200px';
        textarea.style.overflowY = 'auto';
      }
    }
  };

  // Call updateTextFieldHeight only once when component mounts
  useEffect(() => {
    updateTextFieldHeight();
  }, [isMobile]); // Only re-run if isMobile changes

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2, mb: 2 }}>
      <motion.div layout>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
              <TextField
                ref={textFieldRef}
                fullWidth
                variant="outlined"
                placeholder="Type your message..."
                value={message}
                onChange={handleTextChange}
                multiline
                maxRows={6}
                sx={{
                  '& .MuiInputBase-root': {
                    p: isMobile ? 1 : 2,
                    alignItems: 'flex-start',
                    minHeight: isMobile ? '56px' : 'auto',
                  },
                  '& .MuiInputBase-input': {
                    minHeight: '24px',
                    maxHeight: '120px',
                    paddingRight: isMobile ? '140px' : '0',
                    overflow: 'auto',
                    scrollbarWidth: 'none',
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    },
                  },
                  '& textarea': {
                    resize: 'none',
                    lineHeight: '1.5',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <Box sx={{ 
                      display: 'flex', 
                      alignItems: 'flex-end',
                      position: isMobile ? 'absolute' : 'relative',
                      right: isMobile ? 8 : 'auto',
                      bottom: isMobile ? 8 : 'auto',
                      backgroundColor: theme.palette.background.paper,
                      paddingLeft: 1,
                      gap: 0.5,
                    }}>
                      <Tooltip title="Add emoji" arrow>
                        <IconButton 
                          onClick={handleOpenEmojiPicker} 
                          size="small"
                        >
                          <EmojiEmotions fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Attach cars" arrow>
                        <IconButton 
                          onClick={handleOpenCarSearch} 
                          disabled={includeRegistrationForm}
                          size="small"
                        >
                          <AttachIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={includeRegistrationForm ? "Remove registration form" : "Add registration form"} arrow>
                        <IconButton 
                          onClick={toggleRegistrationForm} 
                          color={includeRegistrationForm ? "primary" : "default"} 
                          disabled={attachedCars.length > 0}
                          size="small"
                        >
                          <AssignmentIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Send message" arrow>
                        <SendButton 
                          type="submit" 
                          disabled={isSubmitDisabled()}
                          className={isSending ? 'sending' : ''}
                          size="small"
                        >
                          <SendIcon fontSize="small" />
                        </SendButton>
                      </Tooltip>
                    </Box>
                  ),
                }}
              />
            </motion.div>
          </Grid>
          <AnimatePresence>
            {attachedCars.length > 0 && (
              <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom>
                  Attached Cars:
                </Typography>
                <CarAttachmentList cars={attachedCars} onRemoveCar={handleRemoveCar} />
              </Grid>
            )}
          </AnimatePresence>
          {includeRegistrationForm && (
            <Grid item xs={12}>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                Registration form will be included with this message.
              </Typography>
            </Grid>
          )}
        </Grid>
      </motion.div>

      <Dialog 
        open={isCarSearchOpen} 
        onClose={handleCloseCarSearch} 
        fullScreen
        TransitionComponent={Zoom}
        transitionDuration={300}
      >
        <DialogTitle>
          Select Cars
          <IconButton
            aria-label="close"
            onClick={handleCloseCarSearch}
            sx={{ position: 'absolute', right: 10, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CarSearchPage onSelectCars={handleSelectCars} initialSelectedCars={attachedCars} />
        </DialogContent>
      </Dialog>

      <Popover
        open={Boolean(emojiAnchorEl)}
        anchorEl={emojiAnchorEl}
        onClose={handleCloseEmojiPicker}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <EmojiPicker onEmojiClick={handleEmojiClick} />
      </Popover>
    </Box>
  );
};

export default SendMessageForm;