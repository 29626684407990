import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  Container, Grid, Box, Typography, Card, CardContent, Button, CircularProgress,
  Snackbar, Alert, createTheme, ThemeProvider, CssBaseline, TextField, 
  Pagination, Select, MenuItem, FormControl, InputLabel, Slider, Dialog, DialogContent
} from '@mui/material';
import { Search, DirectionsCar, LocalGasStation, Speed } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import BrandPriorityManager from './BrandPriorityManager';
import { useAuth } from '../contexts/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4361EE',
    },
    secondary: {
      main: '#4895EF',
    },
    background: {
      default: '#F8F9FA',
      paper: '#FFFFFF',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out',
          '&:hover': {
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
            transform: 'translateY(-5px)',
          },
        },
      },
    },
  },
});

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/car_stock/api';

const CarCard = ({ car, canEdit }) => (
  <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardContent sx={{ flexGrow: 1 }}>
      <Typography gutterBottom variant="h5" component="div" sx={{ fontWeight: 'bold' }}>
        {car.brand} {car.model}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <DirectionsCar sx={{ mr: 1, color: 'secondary.main' }} />
        <Typography variant="body2" color="text.secondary">
          {car.version}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <LocalGasStation sx={{ mr: 1, color: 'secondary.main' }} />
        <Typography variant="body2" color="text.secondary">
          {car.fuel}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Speed sx={{ mr: 1, color: 'secondary.main' }} />
        <Typography variant="body2" color="text.secondary">
          {car.km?.toLocaleString()} km
        </Typography>
      </Box>
      <Typography variant="h6" color="primary" sx={{ fontWeight: 'bold' }}>
        €{car.saleprice?.toLocaleString()}
      </Typography>
    </CardContent>
    {canEdit && (
      <Box sx={{ p: 2 }}>
        <Button component={Link} to={`/cars/${car.vehicleid}/edit`} variant="contained" fullWidth>
          Edit
        </Button>
      </Box>
    )}
  </Card>
);

const CarStockList = () => {
  const [carStocks, setCarStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    min_year: '',
    max_year: '',
    brand: '',
    fuel: '',
    bodystyle: '',
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    fuel_types: [],
    body_styles: [],
    price_range: { min_price: 0, max_price: 100000 },
    year_range: { min_year: 2000, max_year: new Date().getFullYear() },
  });

  const [openBrandPriority, setOpenBrandPriority] = useState(false);

  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['car_stock'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canCreate = permissions.can_create || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/carstocks/filter_options/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFilterOptions(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setSnackbarMessage('Failed to fetch filter options. Please refresh the page.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  }, []);

  const fetchCarStocks = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${API_BASE_URL}/carstocks/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          ...filters,
          search: searchTerm,
          page: page
        }
      });
      setCarStocks(response.data.results);
      setTotalPages(Math.ceil(response.data.count / response.data.results.length));
    } catch (error) {
      console.error('Error fetching car stocks:', error);
      setError('An error occurred while fetching car stocks. Please try again.');
      setCarStocks([]);
      setTotalPages(1);
      setSnackbarMessage('Failed to fetch results. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  }, [filters, searchTerm, page]);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  useEffect(() => {
    fetchCarStocks();
  }, [fetchCarStocks]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
    setPage(1);
  };

  const handlePriceChange = (event, newValue) => {
    setFilters({ ...filters, min_price: newValue[0], max_price: newValue[1] });
    setPage(1);
  };

  const handleYearChange = (event, newValue) => {
    setFilters({ ...filters, min_year: newValue[0], max_year: newValue[1] });
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOpenBrandPriority = () => {
    if (!canEdit) {
      setSnackbarMessage('You do not have permission to manage brand priorities.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
      return;
    }
    setOpenBrandPriority(true);
  };

  const handleCloseBrandPriority = () => {
    setOpenBrandPriority(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main', textAlign: 'center', mb: 3 }}>
            Car Stock Management
          </Typography>
          <Grid container spacing={3} sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Search cars"
                variant="outlined"
                value={searchTerm}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: <Search color="action" />,
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Brand</InputLabel>
                <Select
                  name="brand"
                  value={filters.brand}
                  onChange={handleFilterChange}
                  label="Brand"
                >
                  <MenuItem value="">All Brands</MenuItem>
                  {filterOptions.brands.map((brand) => (
                    <MenuItem key={brand} value={brand}>{brand}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Fuel Type</InputLabel>
                <Select
                  name="fuel"
                  value={filters.fuel}
                  onChange={handleFilterChange}
                  label="Fuel Type"
                >
                  <MenuItem value="">All Fuel Types</MenuItem>
                  {filterOptions.fuel_types.map((fuelType) => (
                    <MenuItem key={fuelType} value={fuelType}>{fuelType}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
              <FormControl fullWidth>
                <InputLabel>Body Style</InputLabel>
                <Select
                  name="bodystyle"
                  value={filters.bodystyle}
                  onChange={handleFilterChange}
                  label="Body Style"
                >
                  <MenuItem value="">All Body Styles</MenuItem>
                  {filterOptions.body_styles.map((bodyStyle) => (
                    <MenuItem key={bodyStyle} value={bodyStyle}>{bodyStyle}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Price Range</Typography>
              <Slider
                value={[filters.min_price || filterOptions.price_range.min_price, filters.max_price || filterOptions.price_range.max_price]}
                onChange={handlePriceChange}
                valueLabelDisplay="auto"
                min={filterOptions.price_range.min_price}
                max={filterOptions.price_range.max_price}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography gutterBottom>Year Range</Typography>
              <Slider
                value={[filters.min_year || filterOptions.year_range.min_year, filters.max_year || filterOptions.year_range.max_year]}
                onChange={handleYearChange}
                valueLabelDisplay="auto"
                min={filterOptions.year_range.min_year}
                max={filterOptions.year_range.max_year}
              />
            </Grid>
          </Grid>
          
          {canEdit && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 4 }}>
              <Button 
                variant="contained" 
                color="secondary" 
                onClick={handleOpenBrandPriority}
                sx={{ 
                  fontSize: '1rem', 
                  py: 1, 
                  px: 3,
                  borderRadius: 2,
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                  '&:hover': {
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.15)',
                  }
                }}
              >
                Manage Brand Priorities
              </Button>
            </Box>
          )}
          
          {loading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              {carStocks.length === 0 ? (
                <Typography align="center" sx={{ mt: 4 }}>
                  No results found. Try adjusting your search or filters.
                </Typography>
              ) : (
                <>
                  <Grid container spacing={3}>
                    {carStocks.map((car) => (
                      <Grid item xs={12} sm={6} md={4} key={car.vehicleid}>
                        <CarCard car={car} canEdit={canEdit} />
                      </Grid>
                    ))}
                  </Grid>
                  
                  <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <Pagination 
                      count={totalPages} 
                      page={page} 
                      onChange={handlePageChange} 
                      color="primary" 
                      size="large"
                    />
                  </Box>
                </>
              )}
            </>
          )}
        </Container>

        <Snackbar 
          open={snackbarOpen} 
          autoHideDuration={6000} 
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>

        <Dialog open={openBrandPriority} onClose={handleCloseBrandPriority} fullWidth maxWidth="md">
          <DialogContent>
            <BrandPriorityManager availableBrands={filterOptions.brands} />
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default CarStockList;