import React, { useState, useEffect, useRef } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import ReactMarkdown from 'react-markdown';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Snackbar,
  CircularProgress,
  Chip,
  List,
  ListItem,
  Divider,
  Box,
  Paper,
  Avatar,
  IconButton,
  Tooltip,
  Fade,
  Zoom,
  Slide,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
} from '@mui/material';
import {
  Edit as EditIcon,
  Save as SaveIcon,
  Person as PersonIcon,
  SmartToy as AIIcon,
  DirectionsCar as CarIcon,
  CheckCircle as CheckIcon,
  Error as ErrorIcon,
  ThumbUp,
  ThumbDown,
  Comment as CommentIcon,
  ExpandMore as ExpandMoreIcon,
  Label as LabelIcon,
  ArrowBack as ArrowBackIcon,
  Description as DescriptionIcon,
  Close as CloseIcon,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  AudioFile as AudioIcon,
  BrokenImage as BrokenImageIcon,
} from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import MuiAlert from '@mui/material/Alert';
import { useAuth } from '../contexts/AuthContext';
import SendMessageForm from './SendMessageForm';
import { useNavigate } from 'react-router-dom';
import MobileStepper from '@mui/material/MobileStepper';

const WSS_BASE_URL = process.env.REACT_APP_WSS_BASE_URL || 'wss://stage-admin.aitomotivelab.com';
const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || 'https://stage-admin.aitomotivelab.com';

// Styled Components
const RootContainer = styled(Container)(({ theme, isSmallScreen }) => ({
  height: '100vh',
  overflow: 'hidden',
  padding: isSmallScreen ? 0 : theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 0,
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#f5f5f5',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: 16,
  overflow: 'hidden',
  position: 'relative',
  height: 'calc(100vh - 100px)',
  display: 'flex',
  flexDirection: 'column',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: '4px',
    background: 'linear-gradient(90deg, #FF6B6B, #4ECDC4, #45B7D1)',
  },
}));

const ChatContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  overflow: 'hidden',
}));

const HeaderSection = styled(Box)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  backgroundColor: theme.palette.background.paper,
  zIndex: 10,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(3),
  },
}));

const MessageList = styled(List)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: theme.spacing(2),
  margin: 0,
  '&::-webkit-scrollbar': {
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: theme.palette.primary.dark,
  },
}));

const FixedBottomBox = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2, 3),
  },
  zIndex: 10,
}));

const AnimatedIconButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s cubic-bezier(0.4, 0, 0.2, 1)',
  '&:hover': {
    transform: 'scale(1.2) rotate(5deg)',
    backgroundColor: theme.palette.primary.light,
  },
}));

const MessagePaper = styled(Paper)(({ theme, isBot, isWhatsApp }) => ({
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(1),
  backgroundColor: isBot
    ? isWhatsApp
      ? '#DCF8C6'
      : theme.palette.primary.light
    : isWhatsApp
    ? '#E2E2E2'
    : theme.palette.background.paper,
  color: isBot
    ? isWhatsApp
      ? '#000000'
      : theme.palette.primary.contrastText
    : theme.palette.text.primary,
  borderRadius: 12,
  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.15)',
  },
  maxWidth: '95%',
  wordBreak: 'break-word',
  overflowWrap: 'break-word',
  whiteSpace: 'pre-wrap',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(2),
    maxWidth: '80%',
    borderRadius: 16,
    '&:hover': {
      transform: 'translateY(-5px)',
    },
  },
}));

const SummaryAccordion = styled(Accordion)(({ theme }) => ({
  margin: 0,
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const SummaryAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#2A2F3B' : '#E8F0FE',
  minHeight: 48,
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
}));

const SummaryAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#F9FAFB',
  padding: theme.spacing(1, 2),
}));

const CarAttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  gap: theme.spacing(2),
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: theme.palette.background.default,
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '3px',
  },
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    overflowX: 'visible',
  },
}));

const CarStockCard = ({ car, isSmallScreen }) => (
  <motion.div
    whileHover={{ scale: 1.02 }}
    whileTap={{ scale: 0.98 }}
    transition={{ duration: 0.2 }}
  >
    <Card
      sx={{
        width: isSmallScreen ? '200px' : '100%',
        flexShrink: 0,
        m: 0,
        boxShadow: 3,
        borderRadius: 2,
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        component="img"
        height="120"
        image={car.urlmainimage || '/api/placeholder/345/140'}
        alt={`${car.brand} ${car.model}`}
        sx={{ objectFit: 'cover' }}
      />
      <CardContent sx={{ p: 1.5, '&:last-child': { pb: 1.5 } }}>
        <Typography variant="subtitle2" noWrap>
          {car.brand} {car.model}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          €{car.saleprice?.toLocaleString()}
        </Typography>
        <Typography variant="caption" color="text.secondary" display="block">
          {car.registrationyear} • {car.km?.toLocaleString()} km
        </Typography>
      </CardContent>
    </Card>
  </motion.div>
);

const MobileCarouselContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  overflowX: 'hidden',
  '& .carousel-content': {
    width: '100%',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const CarouselArrowButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  backgroundColor: theme.palette.background.paper,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  zIndex: 2,
  boxShadow: theme.shadows[2],
}));

const MobileCarCarousel = ({ cars }) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = cars.length;

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  return (
    <MobileCarouselContainer>
      <Box className="carousel-content" sx={{ width: '100%', position: 'relative' }}>
        <CarouselArrowButton
          size="small"
          onClick={handleBack}
          disabled={activeStep === 0}
          sx={{ left: 8 }}
        >
          <KeyboardArrowLeft />
        </CarouselArrowButton>

        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            padding: '0 40px',
          }}
        >
          <AnimatePresence mode="wait">
            <motion.div
              key={activeStep}
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.3 }}
            >
              <CarStockCard car={cars[activeStep]} isSmallScreen={true} />
            </motion.div>
          </AnimatePresence>
        </Box>

        <CarouselArrowButton
          size="small"
          onClick={handleNext}
          disabled={activeStep === maxSteps - 1}
          sx={{ right: 8 }}
        >
          <KeyboardArrowRight />
        </CarouselArrowButton>
      </Box>

      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        sx={{
          width: '100%',
          justifyContent: 'center',
          background: 'transparent',
          mt: 1,
          '& .MuiMobileStepper-dot': {
            margin: '0 4px',
          },
        }}
      />
    </MobileCarouselContainer>
  );
};

const ChatHistoryDetail = ({ chatId, humanControl }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const [chatHistory, setChatHistory] = useState([]);
  const [chatSummary, setChatSummary] = useState('');
  const [labelSegmentation, setLabelSegmentation] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editing, setEditing] = useState(false);
  const [editingMessage, setEditingMessage] = useState(null);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', type: 'success' });
  const [feedbackDialog, setFeedbackDialog] = useState({ open: false, messageId: null });
  const [feedback, setFeedback] = useState({ category: '', comment: '' });
  const lastMessageRef = useRef(null);
  const [unsentMessage, setUnsentMessage] = useState(null);
  const [summaryExpanded, setSummaryExpanded] = useState(false);
  const wsRef = useRef(null);
  const [authError, setAuthError] = useState(false);
  const isManuallyClosed = useRef(false);
  const reconnectionAttempts = useRef(0);
  const tempIdCounter = useRef(0);
  const [summaryDialogOpen, setSummaryDialogOpen] = useState(false);

  const { userRole, appAccess, token = localStorage.getItem('token') } = useAuth();
  const permissions = appAccess['ai_app'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';

  const FileDisplay = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  }));

  useEffect(() => {
    let isCancelled = false;
    isManuallyClosed.current = false;
    setLoading(true);
    setChatHistory([]);
    setChatSummary('');
    setLabelSegmentation('');

    const connectWebSocket = () => {
      if (wsRef.current) {
        wsRef.current.close();
      }

      wsRef.current = new WebSocket(`${WSS_BASE_URL}/ws/admin_chat/?token=${token}`);

      wsRef.current.onopen = () => {
        console.log('WebSocket Connected');
        setAuthError(false);
        reconnectionAttempts.current = 0;
        wsRef.current.send(JSON.stringify({ action: 'full_chat', pk: chatId }));
      };

      wsRef.current.onmessage = (event) => {
        let data;
        try {
          data = JSON.parse(event.data);
        } catch (e) {
          console.error('Failed to parse message', e);
          return;
        }
        if (data.type === 'error' && data.message === 'Authentication required.') {
          setAuthError(true);
          wsRef.current.close();
        } else {
          console.log('MESSAGE RECEIVED', data);
          handleWebSocketMessage(data);
        }
      };

      wsRef.current.onerror = (error) => {
        console.error('WebSocket Error:', error);
        setError('Error connecting to chat');
      };

      wsRef.current.onclose = (event) => {
        console.log('WebSocket Disconnected', event.code, event.reason);
        if (!authError && !isManuallyClosed.current && !isCancelled) {
          reconnectionAttempts.current += 1;
          const delay = Math.min(10000, 1000 * Math.pow(2, reconnectionAttempts.current));
          setTimeout(() => connectWebSocket(), delay);
        }
      };
    };

    connectWebSocket();

    return () => {
      isCancelled = true;
      isManuallyClosed.current = true;
      if (wsRef.current) {
        wsRef.current.close();
      }
    };
  }, [chatId, token]);

  const handleWebSocketMessage = (data) => {
    switch (data.action || data.type) {
      case 'full_chat':
        console.log('full_chat:', data.data);
        setChatHistory(data.data);
        setChatSummary(data.data[0]?.chat?.summary || '');
        setLabelSegmentation(data.label_segmentation || '');
        setLoading(false);
        break;
      case 'new_message':
      case 'update_chat':
        const incomingMessage = data.data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex(
            (msg) => msg.id === incomingMessage.id || msg.id === incomingMessage.messageId
          );
          if (index !== -1) {
            const updatedHistory = [...prevHistory];
            updatedHistory[index] = { ...updatedHistory[index], ...incomingMessage };
            return updatedHistory;
          } else {
            return [...prevHistory, incomingMessage];
          }
        });
        break;
      case 'message_sent':
        const { chatHistoryId, temp_id } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === temp_id);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              id: chatHistoryId,
              isTemp: false,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        setUnsentMessage(null);
        break;
      case 'message_status_updated':
        const { chatHistoryId: updatedId, sent } = data;
        setChatHistory((prevHistory) => {
          const index = prevHistory.findIndex((msg) => msg.id === updatedId);
          if (index !== -1) {
            const updatedMessage = {
              ...prevHistory[index],
              sent: sent,
            };
            const newHistory = [...prevHistory];
            newHistory[index] = updatedMessage;
            return newHistory;
          }
          return prevHistory;
        });
        break;
      default:
        console.log('Unhandled WebSocket message:', data);
    }
  };

  useEffect(() => {
    if (chatHistory.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatHistory]);

  useEffect(() => {
    const lastMessage = chatHistory[chatHistory.length - 1];
    if (lastMessage && lastMessage.sent === false && lastMessage.sender_message) {
      setUnsentMessage(lastMessage);
    } else {
      setUnsentMessage(null);
    }
  }, [chatHistory]);

  const sendWebSocketMessage = (message) => {
    if (wsRef.current && wsRef.current.readyState === WebSocket.OPEN) {
      wsRef.current.send(JSON.stringify(message));
    } else {
      console.error('WebSocket is not open. Cannot send message.');
      setSnackbar({ open: true, message: 'Error: WebSocket is not connected', type: 'error' });
    }
  };

  const handleSendMessage = (message) => {
    // Check if there is a last unsent message
    if (unsentMessage) {
      // Update the last unsent message to sent
      sendWebSocketMessage({
        action: 'update_message_status',
        chatHistoryId: unsentMessage.id,
        sent: true,
      });
    }

    const tempId = `temp-${tempIdCounter.current++}`;
    const tempMessage = {
      id: tempId,
      bot_message: message.text,
      created_at: new Date().toISOString(),
      sent: true,
      isTemp: true,
      car_stocks: message.cars,
    };
    setChatHistory((prevHistory) => [...prevHistory, tempMessage]);

    sendWebSocketMessage({
      action: 'send_message',
      chatId: chatId,
      messageId: tempId,
      message: message.text,
      car_stocks: message.cars,
      sent: true,
      include_registration_form: message.include_registration_form,
    });
  };

  const handleFeedback = (messageId, type) => {
    if (type === 'comment') {
      setFeedbackDialog({ open: true, messageId });
    } else {
      sendWebSocketMessage({
        action: 'send_feedback',
        messageId: messageId,
        feedbackType: type,
      });
    }
  };

  const handleFeedbackSubmit = () => {
    sendWebSocketMessage({
      action: 'send_feedback',
      messageId: feedbackDialog.messageId,
      feedback: feedback,
    });
    setFeedbackDialog({ open: false, messageId: null });
    setFeedback({ category: '', comment: '' });
  };

  const handleEdit = (message) => {
    setEditingMessage(message);
    setEditing(true);
  };

  const handleSave = () => {
    sendWebSocketMessage({
      action: 'edit_message',
      message: editingMessage,
    });
    setEditing(false);
    setEditingMessage(null);
  };

  const handleChange = (e) => {
    setEditingMessage({ ...editingMessage, [e.target.name]: e.target.value });
  };

  const MarkdownContent = ({ content }) => (
    <ReactMarkdown
      components={{
        h1: ({ node, ...props }) => <Typography variant="h4" gutterBottom {...props} />,
        h2: ({ node, ...props }) => <Typography variant="h5" gutterBottom {...props} />,
        h3: ({ node, ...props }) => <Typography variant="h6" gutterBottom {...props} />,
        p: ({ node, ...props }) => <Typography variant="body1" paragraph {...props} />,
        a: ({ node, ...props }) => <a style={{ color: theme.palette.primary.main }} {...props} />,
        code: ({ node, inline, className, children, ...props }) => {
          const match = /language-(\w+)/.exec(className || '');
          return !inline && match ? (
            <SyntaxHighlighter
              style={vscDarkPlus}
              language={match[1]}
              PreTag="div"
              {...props}
            >
              {String(children).replace(/\n$/, '')}
            </SyntaxHighlighter>
          ) : (
            <code className={className} {...props}>
              {children}
            </code>
          );
        },
      }}
    >
      {content}
    </ReactMarkdown>
  );

  const handleSummaryClick = () => {
    setSummaryDialogOpen(true);
  };

  const handleCloseSummaryDialog = () => {
    setSummaryDialogOpen(false);
  };

  const ChatSummaryAccordion = () => {
    if (isSmallScreen) {
      return (
        <Tooltip title="Chat Summary">
          <IconButton onClick={handleSummaryClick} size="small">
            <DescriptionIcon />
          </IconButton>
        </Tooltip>
      );
    }

    return (
      <SummaryAccordion
        expanded={summaryExpanded}
        onChange={() => setSummaryExpanded(!summaryExpanded)}
        sx={{
          mb: 0,
          '& .MuiAccordionSummary-root': {
            minHeight: 48,
          },
          '& .MuiAccordionSummary-content': {
            margin: '8px 0',
          },
        }}
      >
        <SummaryAccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="chat-summary-content"
          id="chat-summary-header"
        >
          <Typography variant="subtitle1">Chat Summary</Typography>
        </SummaryAccordionSummary>
        <SummaryAccordionDetails>
          <Typography variant="body2">{chatSummary}</Typography>
        </SummaryAccordionDetails>
      </SummaryAccordion>
    );
  };

  const getFileUrl = (fileObject) => {
    if (!fileObject) return null;

    // If fileObject is a string, return it directly
    if (typeof fileObject === 'string') return fileObject;

    // If fileObject is an object with a file_url property, return that
    if (typeof fileObject === 'object' && fileObject.file_url) {
      return fileObject.file_url;
    }

    return null;
  };

  const getAbsoluteUrl = (relativeUrl, token, chatId) => {
    if (!relativeUrl) return null;

    const urlString = String(relativeUrl);

    // If URL is already absolute, return it without adding parameters
    if (urlString.startsWith('http://') || urlString.startsWith('https://')) {
      return urlString;
    }

    // Build the base URL
    const baseUrl = `${API_DOMAIN}${urlString.startsWith('/') ? '' : '/'}${urlString}`;

    // Add token and chat_id as query parameters if they exist
    const params = new URLSearchParams();
    if (token) params.append('token', token);
    if (chatId) params.append('chat_id', chatId);

    const queryString = params.toString();
    console.log(queryString);
    return queryString ? `${baseUrl}?${queryString}` : baseUrl;
  };

  const getFileDetails = (fileUrl) => {
    if (!fileUrl) return { fileName: 'Unknown File', fileExtension: '' };

    const urlString = String(fileUrl);
    const urlWithoutParams = urlString.split('?')[0];
    const urlParts = urlWithoutParams.split('/');
    const fileName = urlParts[urlParts.length - 1];
    const fileExtension = fileName.split('.').pop()?.toLowerCase() || '';

    return { fileName, fileExtension };
  };

  const renderFile = (fileObject, isBot, currentToken, currentChatId) => {
    const fileUrl = getFileUrl(fileObject);
    if (!fileUrl) {
      console.log('No file URL provided:', fileObject);
      return null;
    }

    // Get file details from the original URL (without parameters)
    const { fileName, fileExtension } = getFileDetails(fileUrl);

    // Generate the absolute URL with parameters
    const absoluteUrl = getAbsoluteUrl(fileUrl, currentToken, currentChatId);
    if (!absoluteUrl) {
      console.error('Unable to generate absolute URL for:', fileUrl);
      return null;
    }

    // Log the URL being used
    console.log('Attempting to load file from:', absoluteUrl);

    // Expanded file type checks
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];
    const isImage = imageExtensions.includes(fileExtension.toLowerCase());
    const isAudio = ['mp3', 'wav', 'ogg', 'm4a', 'aac'].includes(fileExtension.toLowerCase());

    // Create URL object to check validity
    let isValidUrl = false;
    try {
      new URL(absoluteUrl);
      isValidUrl = true;
    } catch (e) {
      console.error('Invalid URL:', absoluteUrl, e);
    }

    return (
      <FileDisplay>
        {isImage ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                minHeight: '200px',
                position: 'relative',
                bgcolor: 'background.paper',
                borderRadius: 1,
                overflow: 'hidden',
                boxShadow: 1,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {isValidUrl && (
                <>
                  <img
                    src={absoluteUrl}
                    alt={fileName}
                    style={{
                      display: 'block',
                      width: '100%',
                      height: 'auto',
                      maxHeight: '400px',
                      objectFit: 'contain',
                    }}
                    onError={(e) => {
                      console.error('Image load error for URL:', absoluteUrl);
                      console.error('File object:', fileObject);
                      console.error('Error event:', e);

                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = '/api/placeholder/400/300';
                      e.target.style.opacity = '0.5'; // Show it's a fallback
                    }}
                    onLoad={(e) => {
                      console.log('Image loaded successfully:', absoluteUrl);
                      e.target.style.opacity = '1';
                    }}
                  />
                  <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundColor: 'rgba(255, 255, 255, 0.8)',
                      opacity: 0,
                      transition: 'opacity 0.3s',
                      '&.loading': {
                        opacity: 1,
                      },
                    }}
                    className="image-loading"
                  >
                    <CircularProgress size={40} />
                  </Box>
                </>
              )}
            </Box>

            <Box
              sx={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName} {!isValidUrl && '(Invalid URL)'}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download image">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <ImageIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : isAudio ? (
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                width: '100%',
                maxWidth: '500px',
                bgcolor: 'background.paper',
                borderRadius: 1,
                p: 2,
                boxShadow: 1,
              }}
            >
              <audio
                controls
                style={{ width: '100%' }}
                src={absoluteUrl}
                onError={(e) => {
                  console.error('Audio load error:', e);
                  console.error('Audio URL:', absoluteUrl);
                }}
              >
                Your browser does not support the audio element.
              </audio>
            </Box>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
                maxWidth: '500px',
                px: 1,
              }}
            >
              <Typography variant="caption" color="text.secondary">
                {fileName}
              </Typography>
              {isValidUrl && (
                <Tooltip title="Download audio">
                  <IconButton
                    component="a"
                    href={absoluteUrl}
                    download={fileName}
                    target="_blank"
                    rel="noopener noreferrer"
                    size="small"
                  >
                    <AudioIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              bgcolor: 'background.paper',
              borderRadius: 1,
              p: 2,
              boxShadow: 1,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <FileIcon color="primary" />
              <Typography variant="body2">{fileName}</Typography>
            </Box>
            {isValidUrl && (
              <Tooltip title="Download file">
                <IconButton
                  component="a"
                  href={absoluteUrl}
                  download={fileName}
                  target="_blank"
                  rel="noopener noreferrer"
                  size="small"
                >
                  <FileIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        )}
      </FileDisplay>
    );
  };

  const LabelSegmentationAccordion = () => (
    <SummaryAccordion>
      <SummaryAccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="label-segmentation-content"
        id="label-segmentation-header"
      >
        <Typography variant="h6">
          <LabelIcon sx={{ mr: 1, verticalAlign: 'middle' }} />
          Label Segmentation
        </Typography>
      </SummaryAccordionSummary>
      <SummaryAccordionDetails>
        <Typography>{labelSegmentation}</Typography>
      </SummaryAccordionDetails>
    </SummaryAccordion>
  );

  const handleBackClick = () => {
    navigate(-1);
  };

  if (loading)
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={60} thickness={4} />
      </Box>
    );
  if (error)
    return (
      <Typography color="error" variant="h5" align="center">
        {error}
      </Typography>
    );
  if (chatHistory.length === 0)
    return (
      <Typography variant="h5" align="center">
        No chat history found
      </Typography>
    );

  return (
    <Container maxWidth={isSmallScreen ? 'sm' : 'lg'}>
      <StyledPaper elevation={3}>
        {/* Modified Header Section */}
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            backgroundColor: theme.palette.background.paper,
            borderBottom: `1px solid ${theme.palette.divider}`,
            p: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              mb: isSmallScreen ? 0 : 2,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleBackClick} sx={{ mr: 2 }} aria-label="back">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6">Chat Details</Typography>
            </Box>
            {isSmallScreen && <ChatSummaryAccordion />}
          </Box>
          {!isSmallScreen && (
            <>
              <ChatSummaryAccordion />
              {labelSegmentation && <LabelSegmentationAccordion />}
            </>
          )}
        </Box>

        {/* Scrollable Message List */}
        <MessageList>
          <AnimatePresence>
            {chatHistory
              .sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
              .map((message, index) => (
                <motion.div
                  key={message.id}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                  ref={index === chatHistory.length - 1 ? lastMessageRef : null}
                >
                  <ListItem
                    alignItems="flex-start"
                    sx={{
                      flexDirection: 'column',
                      padding: theme.spacing(1),
                      width: '100%',
                      [theme.breakpoints.down('sm')]: {
                        padding: theme.spacing(0.5),
                      },
                    }}
                  >
                    {/* Message header with avatar and timestamp */}
                    <Box
                      sx={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        mb: 1,
                        [theme.breakpoints.down('sm')]: {
                          mb: 0.5,
                        },
                      }}
                    >
                      <Avatar
                        sx={{
                          bgcolor: message.sender_message
                            ? theme.palette.secondary.main
                            : theme.palette.primary.main,
                          width: 24,
                          height: 24,
                        }}
                      >
                        {message.sender_message ? (
                          <PersonIcon sx={{ fontSize: 16 }} />
                        ) : (
                          <AIIcon sx={{ fontSize: 16 }} />
                        )}
                      </Avatar>
                      <Typography variant="caption" color="text.secondary">
                        {new Date(message.created_at).toLocaleString()}
                      </Typography>
                    </Box>

                    {/* Message content */}
                    <Box
                      sx={{
                        width: '100%',
                        [theme.breakpoints.down('sm')]: {
                          pl: 0, // Remove padding on mobile
                        },
                      }}
                    >
                      {message.sender_message && (
                        <MessagePaper elevation={1} isBot={false} isWhatsApp={message.whatsapp}>
                          <MarkdownContent content={message.sender_message} />
                          {message.car_stocks && message.car_stocks.length > 0 && (
                            isSmallScreen ? (
                              <MobileCarCarousel cars={message.car_stocks} />
                            ) : (
                              <CarAttachmentsContainer>
                                {message.car_stocks.map((car) => (
                                  <CarStockCard
                                    key={car.vehicleid}
                                    car={car}
                                    isSmallScreen={isSmallScreen}
                                  />
                                ))}
                              </CarAttachmentsContainer>
                            )
                          )}
                        </MessagePaper>
                      )}

                      {message.sender_file && (
                        <Box sx={{ alignSelf: 'flex-start', maxWidth: '80%' }}>
                          {message.sender_file &&
                            renderFile(message.sender_file, false, token, chatId)}
                        </Box>
                      )}
                      {message.bot_message && (
                        <MessagePaper elevation={1} isBot={true} isWhatsApp={message.whatsapp}>
                          <MarkdownContent content={message.bot_message} />
                          {message.car_stocks && message.car_stocks.length > 0 && (
                            isSmallScreen ? (
                              <MobileCarCarousel cars={message.car_stocks} />
                            ) : (
                              <CarAttachmentsContainer>
                                {message.car_stocks.map((car) => (
                                  <CarStockCard
                                    key={car.vehicleid}
                                    car={car}
                                    isSmallScreen={isSmallScreen}
                                  />
                                ))}
                              </CarAttachmentsContainer>
                            )
                          )}
                        </MessagePaper>
                      )}
                    </Box>

                    {/* Message actions */}
                    {!message.isTemp && (
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          width: '100%',
                          mt: 1,
                        }}
                      >
                        {canEdit && (
                          <>
                            <Tooltip title="Helpful">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'helpful')}
                              >
                                <ThumbUp fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Not Helpful">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'not_helpful')}
                              >
                                <ThumbDown fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Add Comment">
                              <IconButton
                                size="small"
                                onClick={() => handleFeedback(message.id, 'comment')}
                              >
                                <CommentIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </Box>
                    )}
                  </ListItem>
                  {index < chatHistory.length - 1 && (
                    <Divider variant="fullWidth" sx={{ my: 2 }} />
                  )}
                </motion.div>
              ))}
          </AnimatePresence>
        </MessageList>

        {/* Fixed Bottom Input Section - Only show if humanControl is true */}
        {canEdit && humanControl && (
          <FixedBottomBox>
            <SendMessageForm onSendMessage={handleSendMessage} unsentMessage={unsentMessage} />
          </FixedBottomBox>
        )}

        {/* Show a message when human control is not activated */}
        {canEdit && !humanControl && (
          <FixedBottomBox>
            <Typography variant="body2" color="text.secondary" align="center" sx={{ py: 1 }}>
              Human control must be activated to send messages
            </Typography>
          </FixedBottomBox>
        )}

        {/* Add Summary Dialog for mobile */}
        <Dialog
          fullScreen
          open={summaryDialogOpen}
          onClose={handleCloseSummaryDialog}
          TransitionComponent={Fade}
        >
          <Box
            sx={{
              p: 2,
              backgroundColor: theme.palette.background.paper,
              borderBottom: `1px solid ${theme.palette.divider}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h6">Chat Summary</Typography>
            <IconButton
              edge="end"
              color="inherit"
              onClick={handleCloseSummaryDialog}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <DialogContent>
            <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>
              {chatSummary}
            </Typography>
          </DialogContent>
        </Dialog>
      </StyledPaper>

      {/* Dialogs and Snackbars */}
      <Dialog
        open={feedbackDialog.open}
        onClose={() => setFeedbackDialog({ open: false, messageId: null })}
      >
        {/* ... Dialog content ... */}
      </Dialog>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
      >
        <Alert severity={snackbar.type}>{snackbar.message}</Alert>
      </Snackbar>
    </Container>
  );
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default ChatHistoryDetail;
