import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { 
  Container, 
  Grid, 
  Box, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  ListItemSecondaryAction, 
  IconButton, 
  Button, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  CircularProgress, 
  Snackbar, 
  Alert, 
  TextField, 
  Zoom,
  Chip
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Trash2, Eye, Pencil, List as LucideList, Search, Plus } from 'lucide-react';

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/formbuilder';

const FormList = () => {
  const [forms, setForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [formToDelete, setFormToDelete] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('alphabetical');

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/forms/`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      console.log(localStorage.getItem('token'))
      console.log("form", response.data)
      setForms(response.data);
    } catch (error) {
      console.error('Error fetching forms:', error);
      setError('An error occurred while fetching forms.');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteClick = (form) => {
    setFormToDelete(form);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await axios.delete(`${API_BASE_URL}/api/forms/${formToDelete.id}/`);
      setForms(forms.filter(form => form.id !== formToDelete.id));
      setDeleteDialogOpen(false);
      setFormToDelete(null);
      setSnackbarMessage('Form deleted successfully');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (err) {
      console.error('Error deleting form:', err);
      setSnackbarMessage('Failed to delete form');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setFormToDelete(null);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleViewResponsesClick = (formId) => {
    window.location.href = `/forms/${formId}/responses`;
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredForms = forms
    .filter(form => form.title.toLowerCase().includes(searchTerm.toLowerCase()))
    .sort((a, b) => sortOrder === 'alphabetical' ? a.title.localeCompare(b.title) : new Date(b.created_at) - new Date(a.created_at));

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: '#f0f4f8', py: 8 }}>
      <Container maxWidth="lg">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Typography variant="h3" sx={{ mb: 4, fontWeight: 'bold', color: '#1a237e' }}>
            Il tuo modulo di registrazione
          </Typography>
          <Typography variant="body1" sx={{ mb: 4 }}>
            Qui puoi vedere le singole risposte al tuo modulo di registrazione e modificarlo
          </Typography>
        </motion.div>

        <Grid container spacing={4}>
          <Grid item xs={12} md={8}>
            <List>
              {filteredForms.map((form, index) => (
                <Zoom in={true} style={{ transitionDelay: `${index * 100}ms` }} key={form.id}>
                  <ListItem
                    sx={{
                      mb: 2,
                      bgcolor: 'white',
                      borderRadius: 2,
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      transition: 'transform 0.2s, box-shadow 0.2s',
                      '&:hover': {
                        transform: 'translateY(-4px)',
                        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.15)',
                      },
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Typography fontWeight="bold">{form.title}</Typography>
                          <Chip 
                            label={form.company_name || 'N/A'} 
                            size="small" 
                            color="primary" 
                            variant="outlined"
                          />
                        </Box>
                      }
                      secondary={`Created: ${new Date(form.created_at).toLocaleDateString()}`}
                    />
                    <ListItemSecondaryAction sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        edge="end"
                        aria-label="edit"
                        component={Link}
                        to={`/forms/${form.id}/edit`}
                        sx={{ color: '#4caf50' }}
                      >
                        <Pencil size={20} />
                      </IconButton>
                      <IconButton
                        edge="end"
                        aria-label="responses"
                        onClick={() => handleViewResponsesClick(form.id)}
                        sx={{ color: '#2196f3' }}
                      >
                        <LucideList size={20} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                </Zoom>
              ))}
            </List>
          </Grid>

          <Grid item xs={12} md={4}>
            <Box sx={{ bgcolor: 'white', borderRadius: 2, p: 3, boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
              <Typography variant="h6" sx={{ mb: 2, fontWeight: 'bold' }}>
                Dashboard Overview
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Total Forms: {forms.length}
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Latest Form: {forms.length > 0 ? forms[0].title : 'N/A'}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                fullWidth
                component={Link}
                to="/forms/analytics"
                sx={{ mt: 2 }}
              >
                View Analytics
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default FormList;