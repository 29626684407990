import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, CircularProgress, Alert, TextField, Button, Box, Fade } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { motion } from 'framer-motion';
import Chats from '../chats/Chats';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://stage-admin.aitomotivelab.com';

const AnimatedCard = ({ children }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.5 }}
  >
    <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {children}
    </Card>
  </motion.div>
);
const Analytics = ({ companyId, client }) => {
    const [analyticsData, setAnalyticsData] = useState(null);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [startDate, setStartDate] = useState(getDefaultStartDate());
    const [endDate, setEndDate] = useState(getDefaultEndDate());
  

    console.log("ANALYTICSSSS", client)
    function getDefaultStartDate() {
      const date = new Date();
      date.setMonth(date.getMonth() - 1);
      return date.toISOString().split('T')[0];
    }
  
    function getDefaultEndDate() {
      return new Date().toISOString().split('T')[0];
    }
  
    const fetchAnalytics = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token di autenticazione non trovato');
        }
  
        let apiUrl = `${API_BASE_URL}/ai_app/ai/analytics_company/?start_date=${startDate}&end_date=${endDate}`;
  
        if (client) {
          apiUrl += `&client=${client}`;
        }
  
        const response = await fetch(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
  
        if (!response.ok) {
          throw new Error(`Errore HTTP! stato: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
        setAnalyticsData(data);
      } catch (error) {
        console.error('Errore nel recupero delle analitiche:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchAnalytics();
    }, [companyId, client, startDate, endDate]);
  
    useEffect(() => {
      fetchAnalytics();
    }, [companyId]);
  
    const handleDateChange = () => {
      fetchAnalytics();
    };
  
    if (loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <CircularProgress />
        </Box>
      );
    }
  
    if (error) {
      return <Alert severity="error">Errore: {error}</Alert>;
    }
  
    if (!analyticsData) {
      return <Alert severity="info">Nessun dato disponibile.</Alert>;
    }
  
    const chartData = analyticsData.daily_chat_count?.map(item => ({
      date: item.created_at__date,
      count: item.count
    })) || [];
  
    const pieData = analyticsData.top_categories?.map(category => ({
      id: category.category,
      value: category.count,
      label: category.category
    })) || [];
  
    const handleChatClick = (chatId) => {
      console.log(`Apertura chat con ID: ${chatId}`);
    };
  
    const getTitle = (defaultTitle) => defaultTitle;
  
    return (
      <Box sx={{ p: 3 }}>
        {/* Date and Button Input Section */}
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} md={4}>
            <TextField
              label="Data Inizio"
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              label="Data Fine"
              type="date"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button variant="contained" onClick={handleDateChange} fullWidth sx={{ height: '100%' }}>
              Aggiorna Analitiche
            </Button>
          </Grid>
        </Grid>
  
        {/* Analytics Cards Section */}
        <Fade in={!loading}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Messaggi Totali')}
                  </Typography>
                  <Typography variant="h3">{analyticsData.total_messages}</Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Chat Totali')}
                  </Typography>
                  <Typography variant="h3">{analyticsData.total_chats}</Typography>
                </CardContent>
              </Card>
            </Grid>
           
            {!client && (
              <Grid item xs={12} md={6} lg={3}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="text.secondary">
                      {getTitle('Clienti Unici')}
                    </Typography>
                    <Typography variant="h3">{analyticsData.unique_clients}</Typography>
                  </CardContent>
                </Card>
              </Grid>
            )}
            <Grid item xs={12} md={6} lg={client ? 4 : 3}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Prenotazioni Totali')}
                  </Typography>
                  <Typography variant="h3">{analyticsData.total_bookings}</Typography>
                </CardContent>
              </Card>
            </Grid>
  
            {client && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="text.secondary">
                      {getTitle('Chat Recenti')}
                    </Typography>
                    <Chats chats={analyticsData.recent_chats} onChatClick={handleChatClick} client={client}/>
                  </CardContent>
                </Card>
              </Grid>
            )}
  
            {/* Pie Chart Card
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Categorie Principali dei feedback')}
                  </Typography>
                  {pieData.length > 0 ? (
                    <PieChart
                      series={[
                        {
                          data: pieData,
                          highlightScope: { faded: 'global', highlighted: 'item' },
                          faded: { innerRadius: 30, additionalRadius: -30 },
                        },
                      ]}
                      height={300}
                    />
                  ) : (
                    <Typography>Nessun dato di categoria disponibile</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid> */}
  
            {/* Bar Chart Card */}
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Conteggio Chat Giornaliere')}
                  </Typography>
                  {chartData.length > 0 ? (
                    <BarChart
                      xAxis={[{ scaleType: 'band', data: chartData.map((item) => item.date) }]}
                      series={[{ data: chartData.map((item) => item.count) }]}
                      height={300}
                    />
                  ) : (
                    <Typography>Nessun dato giornaliero di chat disponibile</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
  
            {/* AI Metrics and Brand Distribution Cards */}
            <Grid item xs={12} md={12}>
              <Card>
                <CardContent>
                  <Typography variant="h6" color="text.secondary">
                    {getTitle('Distribuzione Marche')}
                  </Typography>
                  {analyticsData.brand_distribution?.length > 0 ? (
                    <BarChart
                      xAxis={[{ scaleType: 'band', data: analyticsData.brand_distribution.map((brand) => brand.brand) }]}
                      series={[{ data: analyticsData.brand_distribution.map((brand) => brand.count) }]}
                      height={300}
                    />
                  ) : (
                    <Typography>Nessun dato di distribuzione marche disponibile</Typography>
                  )}
                </CardContent>
              </Card>
            </Grid>
  
            {/* Wishlist Section */}
            {client && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="text.secondary">
                      {getTitle('Wishlist')}
                    </Typography>
                    {analyticsData.wishlist?.length > 0 ? (
                      <Grid container spacing={2}>
                        {analyticsData.wishlist.map(item => (
                          <Grid item xs={12} sm={6} md={4} key={item.id}>
                            <Card>
                              <CardContent>
                                <Typography variant="h6">{item.car_stocks__brand} {item.car_stocks__model}</Typography>
                                <img src={item.car_stocks__urlmainimage} alt={item.car_stocks__model} style={{ width: '100%', height: 'auto' }} />
                                <Typography variant="body2">ID Veicolo: {item.car_stocks__vehicleid}</Typography>
                              </CardContent>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography>Nessuna wishlist disponibile</Typography>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            )}
            
            {/* Recent Chats Section */}
            {!client && (
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography variant="h6" color="text.secondary">
                      {getTitle('Chat Recenti')}
                    </Typography>
                    <Chats chats={analyticsData.recent_chats} onChatClick={handleChatClick} client={client}/>
                  </CardContent>
                </Card>
              </Grid>
            )}
          </Grid>
        </Fade>
      </Box>
    );
  };
  
  export default Analytics;