import React from 'react';
import { 
  Typography, Card, CardContent, IconButton, Tooltip,
  CardMedia, CardActions
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Close as CloseIcon } from '@mui/icons-material';
import { motion, AnimatePresence } from 'framer-motion';

const StyledCard = styled(motion(Card))(({ theme }) => ({
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius,
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: theme.shadows[10],
  },
}));

const ScrollableBox = styled(motion.div)(({ theme }) => ({
  display: 'flex',
  overflowX: 'auto',
  padding: theme.spacing(1),
  marginTop: theme.spacing(2),
  '&::-webkit-scrollbar': {
    height: '6px',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: theme.palette.primary.light,
    borderRadius: '3px',
  },
}));

const CarAttachmentCard = ({ car, onRemove }) => (
  <motion.div
    initial={{ opacity: 0, x: 20 }}
    animate={{ opacity: 1, x: 0 }}
    exit={{ opacity: 0, x: -20 }}
    transition={{ duration: 0.3 }}
    style={{ flexShrink: 0, width: '220px', marginRight: '16px' }}
  >
    <StyledCard sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      <CardMedia
        component="img"
        height="120"
        image={car.urlmainimage || "/api/placeholder/220/120"}
        alt={`${car.brand} ${car.model}`}
      />
      <CardContent sx={{ flexGrow: 1, py: 1 }}>
        <Typography variant="subtitle2" noWrap>
          {car.brand} {car.model}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          €{car.saleprice.toLocaleString()}
        </Typography>
        <Typography variant="caption" display="block" color="text.secondary">
          {car.registrationyear} • {car.km.toLocaleString()} km
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ justifyContent: 'flex-end', p: 1 }}>
        <Tooltip title="Remove car" arrow>
          <IconButton size="small" onClick={() => onRemove(car.vehicleid)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </CardActions>
    </StyledCard>
  </motion.div>
);

export const SendButton = styled(IconButton)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:not(:disabled):hover': { 
    transform: 'scale(1.1) rotate(10deg)',
  },
  '&.sending': {
    animation: 'sendPulse 1s infinite',
  },
  '@keyframes sendPulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

export const CarAttachmentList = ({ cars, onRemoveCar }) => (
  <ScrollableBox
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    exit={{ opacity: 0, y: -20 }}
    transition={{ duration: 0.5 }}
  >
    <AnimatePresence>
      {cars.map((car) => (
        <CarAttachmentCard key={car.vehicleid} car={car} onRemove={onRemoveCar} />
      ))}
    </AnimatePresence>
  </ScrollableBox>
);