import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Typography,
  Button,
  Checkbox,
  ListItemText,
  InputAdornment,
} from '@mui/material';
import { Delete, AddCircleOutline, Search } from '@mui/icons-material';
import axios from 'axios';
import ItalianLocationFields from './ItalianLocationFields';
import { useAuth } from '../contexts/AuthContext';

const DOMAIN = process.env.REACT_APP_API_URL || 'https://stage-admin.aitomotivelab.com'
const API_BASE_URL = `${DOMAIN}/gestionale/api`;  // Replace with your actual API URL

const conditionTypes = [
  { value: 'equals', label: 'Equals' },
  { value: 'notEquals', label: 'Not Equals' },
  { value: 'greaterThan', label: 'Greater Than' },
  { value: 'lessThan', label: 'Less Than' },
  { value: 'includes', label: 'Includes' },
];

const fetchWarehouses = async () => {
  try {
    const response = await axios.get(`${API_BASE_URL}/warehouses`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching warehouses:', error);
    return [];
  }
};

const fetchProducts = async (warehouseId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/products/?warehouse=${warehouseId}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
};

const ConditionNode = ({ node, fields, onChange, onDelete, onAdd, showNextPage }) => {
  const [warehouses, setWarehouses] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showWarehouseSelect, setShowWarehouseSelect] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const { canView, canEdit, canDelete, canAdd } = useAuth(); // Use the auth context to manage permissions

  const locationFieldOptions = [
    { field_id: 'region', label: 'Region', field_type: 'italian_location' },
    { field_id: 'province', label: 'Province', field_type: 'italian_location' },
    { field_id: 'municipality', label: 'Municipality', field_type: 'italian_location' },
  ];

  const allFields = fields.reduce((acc, field) => {
    if (field.field_type === 'italian_location') {
      return [...acc, ...locationFieldOptions];
    }
    return [...acc, field];
  }, []);

  useEffect(() => {
    const loadWarehouses = async () => {
      setLoading(true);
      try {
        const data = await fetchWarehouses();
        setWarehouses(data);
        setError(null);
      } catch (err) {
        setError('Failed to load warehouses. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    if (canView) {
      loadWarehouses();
    }
  }, [canView]);

  useEffect(() => {
    const loadProducts = async () => {
      if (node.warehouseId) {
        setLoading(true);
        try {
          const data = await fetchProducts(node.warehouseId);
          setAllProducts(data);
          setError(null);
        } catch (err) {
          setError('Failed to load products. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };

    if (canView) {
      loadProducts();
    }
  }, [node.warehouseId, canView]);

  useEffect(() => {
    if (node.categoryId) {
      const filtered = allProducts.filter(product => product.category.id === node.categoryId);
      setFilteredProducts(filtered);
    } else {
      setFilteredProducts(allProducts);
    }
  }, [node.categoryId, allProducts]);

  useEffect(() => {
    if (node && node.warehouseId) {
      setShowWarehouseSelect(true);
    }
  }, [node]);

  const handleChange = (key, value) => {
    if (!canEdit) return; // Restrict change if the user doesn't have edit permission
    if (key === 'fieldId') {
      const selectedField = allFields.find(f => f.field_id === value);
      onChange({
        ...node,
        [key]: value,
        fieldType: selectedField ? selectedField.field_type : '',
        value: undefined,
        warehouseId: '',
        categoryId: '',
        productIds: [],
        propertyId: ''
      });
      setShowWarehouseSelect(false);
    } else if (key === 'warehouseId') {
      onChange({ ...node, [key]: value, categoryId: '', productIds: [], propertyId: '' });
    } else if (key === 'categoryId') {
      onChange({ ...node, [key]: value, productIds: [], propertyId: '' });
    } else if (key === 'productIds') {
      onChange({ ...node, [key]: value, propertyId: '' });
    } else {
      onChange({ ...node, [key]: value });
    }
  };

  const handleCheckWarehouse = () => {
    setShowWarehouseSelect(true);
    onChange({ ...node, warehouseId: '', categoryId: '', productIds: [], propertyId: '' });
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const renderFieldSelect = () => (
    <FormControl fullWidth margin="dense">
      <InputLabel>Field</InputLabel>
      <Select
        value={node.fieldId || ''}
        onChange={(e) => handleChange('fieldId', e.target.value)}
      >
        {allFields.map((field) => (
          <MenuItem key={field.field_id} value={field.field_id}>
            {field.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  const renderWarehouseCategoryProductPropertySelect = () => (
    <>
      <FormControl fullWidth margin="dense">
        <InputLabel>Warehouse</InputLabel>
        <Select
          value={node.warehouseId || ''}
          onChange={(e) => handleChange('warehouseId', e.target.value)}
        >
          {warehouses.map((warehouse) => (
            <MenuItem key={warehouse.id} value={warehouse.id}>
              {warehouse.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel>Category</InputLabel>
        <Select
          value={node.categoryId || ''}
          onChange={(e) => handleChange('categoryId', e.target.value)}
          disabled={!node.warehouseId}
        >
          {warehouses
            .find(w => w.id === node.warehouseId)
            ?.categories.map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        margin="dense"
        label="Search Products"
        value={searchTerm}
        onChange={handleSearchChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <FormControl fullWidth margin="dense">
        <InputLabel>Products</InputLabel>
        <Select
          multiple
          value={node.productIds || []}
          onChange={(e) => handleChange('productIds', e.target.value)}
          disabled={!node.categoryId}
          renderValue={(selected) => selected.map(id => filteredProducts.find(p => p.id === id)?.name).join(', ')}
        >
          {filteredProducts
            .filter(product => product.name.toLowerCase().includes(searchTerm.toLowerCase()))
            .map((product) => (
              <MenuItem key={product.id} value={product.id}>
                <Checkbox checked={(node.productIds || []).indexOf(product.id) > -1} />
                <ListItemText primary={product.name} />
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel>Property</InputLabel>
        <Select
          value={node.propertyId || ''}
          onChange={(e) => handleChange('propertyId', e.target.value)}
          disabled={!node.productIds || node.productIds.length === 0}
        >
          {filteredProducts
            .filter(p => node.productIds && node.productIds.includes(p.id))
            .flatMap(p => p.properties)
            .filter((property, index, self) => 
              index === self.findIndex((t) => t.id === property.id)
            )
            .map((property) => (
              <MenuItem key={property.id} value={property.id}>
                {property.property_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </>
  );

  const renderValueField = () => {
    const selectedField = allFields.find(f => f.field_id === node.fieldId);
    if (selectedField && selectedField.field_type === 'italian_location' && !showWarehouseSelect) {
      return (
        <ItalianLocationFields
          selectedField={node.fieldId}
          onChange={(subField, value) => handleChange('value', value)}
          disabled={false}
        />
      );
    } else if (showWarehouseSelect) {
      return renderWarehouseCategoryProductPropertySelect();
    } else {
      return (
        <TextField
          margin="dense"
          label="Value"
          type="text"
          fullWidth
          value={node.value || ''}
          onChange={(e) => handleChange('value', e.target.value)}
        />
      );
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {renderFieldSelect()}
        <FormControl fullWidth margin="dense">
          <InputLabel>Condition Type</InputLabel>
          <Select
            value={node.type || ''}
            onChange={(e) => handleChange('type', e.target.value)}
          >
            {conditionTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {renderValueField()}
      {!showWarehouseSelect && node.fieldId && canView && (
        <Button onClick={handleCheckWarehouse} variant="contained" sx={{ mt: 2 }}>
          Check Warehouse Products
        </Button>
      )}
      {showNextPage && (
        <FormControl fullWidth margin="dense">
          <InputLabel>Next Page</InputLabel>
          <Select
            value={node.next_page_id || ''}
            onChange={(e) => handleChange('next_page_id', e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {/* Add pages here if needed */}
          </Select>
        </FormControl>
      )}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        {canDelete && (
          <IconButton onClick={onDelete}>
            <Delete />
          </IconButton>
        )}
        {canAdd && (
          <IconButton onClick={onAdd}>
            <AddCircleOutline />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};

ConditionNode.propTypes = {
  node: PropTypes.shape({
    fieldId: PropTypes.string,
    type: PropTypes.string,
    apiEndpoint: PropTypes.string,
    value: PropTypes.string,
    next_page_id: PropTypes.string,
  }).isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      field_id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  showNextPage: PropTypes.bool.isRequired,
};

export default ConditionNode;
