import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const AvailabilityList = ({ availabilities, handleEditAvailability, handleDeleteAvailability, dayOfWeekOptions }) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this relates to booking permissions
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission
  const canDelete = permissions.can_delete || userRole === 'superadmin'; // Delete permission

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Day of Week</TableCell>
            <TableCell>Start Time</TableCell>
            <TableCell>End Time</TableCell>
            <TableCell>Max Slots</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {availabilities.map((availability) => (
            <TableRow key={availability.id}>
              <TableCell>
                {dayOfWeekOptions.find((option) => option.value === availability.day_of_week)?.label}
              </TableCell>
              <TableCell>{availability.start_time}</TableCell>
              <TableCell>{availability.end_time}</TableCell>
              <TableCell>{availability.max_slots}</TableCell>
              <TableCell>
                {canEdit && (
                  <IconButton onClick={() => handleEditAvailability(availability)} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleDeleteAvailability(availability.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AvailabilityList;
