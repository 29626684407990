import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { Help } from '@mui/icons-material';

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/formbuilder';

const FormResponses = () => {
  const { formId } = useParams();
  const [responses, setResponses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);
  const [columnNames, setColumnNames] = useState(["Answer 1", "Answer 2", "Answer 3"]);

  useEffect(() => {
    fetchResponses();
  }, [formId]);

  const fetchResponses = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${API_BASE_URL}/api/forms/${formId}/responses/`,
        {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`
            }
          }


      );
      setResponses(response.data);
      if (response.data.length > 0) {
        setColumnNames(getFirstThreeAnswerLabels(response.data[0]));
      }
      setLoading(false);
    } catch (err) {
      console.error('Error fetching form responses:', err);
      setError('An error occurred while fetching form responses.');
      setLoading(false);
    }
  };

  const getFieldLabel = (pages, fieldId) => {
    for (const page of pages) {
      for (const group of page.groups) {
        for (const field of group.fields) {
          if (field.id === fieldId) {
            return field.label;
          }
        }
      }
    }
    return 'Unknown Field';
  };

  const getFirstThreeAnswerLabels = (response) => {
    const answers = response.field_responses.slice(0, 3);
    return answers.map((answer) => getFieldLabel(response.form.pages, answer.field));
  };

  const getFirstThreeAnswers = (response) => {
    const answers = response.field_responses.slice(0, 3);
    const answerValues = answers.map((answer) => answer.value);
    while (answerValues.length < 3) {
      answerValues.push(''); // Fill with empty strings if less than 3 answers
    }
    return answerValues;
  };

  const handleClickOpen = (response) => {
    setSelectedResponse(response);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedResponse(null);
  };

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 1200, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom align="center">Form Responses</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Submitted At</TableCell>
              {columnNames.map((name, index) => (
                <TableCell key={index}>{name}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {responses.map((response) => {
              const firstThreeAnswers = getFirstThreeAnswers(response);
              return (
                <TableRow key={response.id}>
                  <TableCell>{new Date(response.submitted_at).toLocaleString()}</TableCell>
                  {firstThreeAnswers.map((answer, index) => (
                    <TableCell key={index}>{answer}</TableCell>
                  ))}
                  <TableCell>
                    <Button variant="outlined" onClick={() => handleClickOpen(response)}>
                      View Details
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>Response Details</DialogTitle>
        <DialogContent>
          {selectedResponse && (
            <Box>
              <Typography variant="h6" gutterBottom>
                Submitted at: {new Date(selectedResponse.submitted_at).toLocaleString()}
              </Typography>
              {selectedResponse.field_responses.map((fieldResponse) => (
                <Box key={fieldResponse.id} sx={{ mb: 2 }}>
                  <Typography variant="body1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                    {getFieldLabel(selectedResponse.form.pages, fieldResponse.field)}:
                    <Tooltip title={getFieldLabel(selectedResponse.form.pages, fieldResponse.field)} arrow placement="top-start">
                      <IconButton size="small" sx={{ ml: 1 }}>
                        <Help fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {fieldResponse.value}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default FormResponses;
