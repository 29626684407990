import React, { useState, useEffect, useCallback } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { 
    Box, Typography, TextField, Button, CssBaseline, Grid, 
    createTheme, ThemeProvider, Dialog, DialogActions, 
    DialogContent, DialogContentText, DialogTitle,
    CircularProgress, Input, Snackbar, Alert, Tooltip, IconButton,
    Accordion, AccordionSummary, AccordionDetails, List, ListItem, ListItemText,
    Fade, Grow, Zoom, Paper
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { itIT } from '@mui/material/locale';
import { fetchCompanies, createCompany, updateCompany, deleteCompany, fetchCurrentUserRole, uploadLogo } from '../components/api/company';
import { MapPin, Mail, Building, Edit, Trash2, Plus, X, ChevronDown, Phone, FileUpIcon, Info, Bot} from 'lucide-react';
import AIManagement from '../components/aimanagement/AiManagement';

const theme = createTheme({
    palette: {
        mode: 'light',
        primary: { main: '#3a86ff' },
        secondary: { main: '#ff006e' },
        background: { default: '#f0f2f5', paper: '#ffffff' },
    },
    typography: {
        fontFamily: '"Poppins", "Roboto", "Helvetica", "Arial", sans-serif',
        h2: {
            fontWeight: 700,
            fontSize: '2.5rem',
            background: 'linear-gradient(45deg, #3a86ff 30%, #ff006e 90%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    textTransform: 'none',
                    fontWeight: 600,
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-2px)',
                        boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    boxShadow: '0 4px 20px 0 rgba(0,0,0,0.12)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        transform: 'translateY(-5px)',
                        boxShadow: '0 12px 28px 0 rgba(0,0,0,0.2)',
                    },
                },
            },
        },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    border: 'none',
                    '& .MuiDataGrid-cell:focus': {
                        outline: 'none',
                    },
                    '& .MuiDataGrid-row': {
                        transition: 'background-color 0.3s ease-in-out',
                    },
                    '& .MuiDataGrid-row:hover': {
                        backgroundColor: alpha('#3a86ff', 0.1),
                    },
                },
            },
        },
    },
}, itIT);

const PaginaAziende = () => {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentCompany, setCurrentCompany] = useState({
        name: '', descrizione: '', codice_concessionario: "", email: '', email_servizio: '', link_gdpr: '',link_website: '',
        partita_iva: '', numero_di_telefono: '', locations: []
    });
    const [currentUser, setCurrentUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [companyToDelete, setCompanyToDelete] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [validationErrors, setValidationErrors] = useState({});
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });
    const [openAIDialog, setOpenAIDialog] = useState(false);
    const [selectedCompanyId, setSelectedCompanyId] = useState(null);

    const showSnackbar = useCallback((message, severity) => {
        setSnackbar({ open: true, message, severity });
    }, []);

    const fetchData = useCallback(async () => {
        setIsLoading(true);
        try {
            const [companiesData, userData] = await Promise.all([
                fetchCompanies(),
                fetchCurrentUserRole()
            ]);
            
            setCurrentUser(userData.role);
            setRows(userData.role === 'superadmin' ? companiesData : companiesData && companiesData.length > 0 ? [companiesData[0]] : []);
        } catch (error) {
            console.error('Error fetching data:', error);
            showSnackbar('Error fetching data. Please try again.', 'error');
        } finally {
            setIsLoading(false);
        }
    }, [showSnackbar]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleOpenAIDialog = useCallback((companyId) => {
        setSelectedCompanyId(companyId);
        setOpenAIDialog(true);
    }, []);

    const handleCloseAIDialog = useCallback(() => {
        setOpenAIDialog(false);
        setSelectedCompanyId(null);
    }, []);

    const validateForm = useCallback(() => {
        const errors = {};
        if (!currentCompany.name) errors.name = 'Name is required';
        if (!currentCompany.email) errors.email = 'Email is required';
        else if (!/\S+@\S+\.\S+/.test(currentCompany.email)) errors.email = 'Email is invalid';
        if (!currentCompany.partita_iva) errors.partita_iva = 'Partita IVA is required';
        
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    }, [currentCompany]);

    const handleAdd = useCallback(() => {
        if (currentUser !== 'superadmin') {
            showSnackbar('Only superadmins can create new companies', 'warning');
            return;
        }
        setCurrentCompany({
            name: '', descrizione: '', codice_concessionario: "", email: '', email_servizio: '', link_gdpr: '',link_website:"",
            partita_iva: '', numero_di_telefono: '', locations: []
        });
        setLogoFile(null);
        setOpen(true);
    }, [currentUser]);

    const handleEdit = useCallback((row) => {
        if (currentUser !== 'superadmin' && currentUser !== 'admin') {
            showSnackbar('Only superadmins and admins can edit companies', 'warning');
            return;
        }
        setCurrentCompany(row);
        setLogoFile(null);
        setOpen(true);
    }, [currentUser]);

    const handleDelete = useCallback((id) => {
        if (currentUser !== 'superadmin') {
            showSnackbar('Only superadmins can delete companies', 'warning');
            return;
        }
        setCompanyToDelete(id);
        setConfirmDeleteOpen(true);
    }, [currentUser]);

    const confirmDelete = useCallback(async () => {
        if (companyToDelete) {
            setIsLoading(true);
            try {
                await deleteCompany(companyToDelete);
                setRows(prevRows => prevRows.filter((row) => row.id !== companyToDelete));
                showSnackbar('Company deleted successfully', 'success');
            } catch (error) {
                console.error('Error deleting company:', error);
                showSnackbar('Error deleting company', 'error');
            } finally {
                setIsLoading(false);
                setConfirmDeleteOpen(false);
                setCompanyToDelete(null);
            }
        }
    }, [companyToDelete]);

    const handleClose = useCallback(() => {
        setOpen(false);
        setCurrentCompany({
            name: '', descrizione: '', email: '', codice_concessionario: "", email_servizio: '', link_gdpr: '',link_website: '',
            partita_iva: '', numero_di_telefono: '', locations: []
        });
        setLogoFile(null);
        setValidationErrors({});
    }, []);

    const handleSave = useCallback(async () => {
        if (!validateForm()) return;

        setIsLoading(true);
        try {
            let updatedCompany;
            if (currentCompany.id) {
                console.log('Updating company with data:', currentCompany);
                
                const updateData = {
                    name: currentCompany.name,
                    descrizione: currentCompany.descrizione,
                    codice_concessionario: currentCompany.codice_concessionario,
                    email: currentCompany.email,
                    email_servizio: currentCompany.email_servizio,
                    link_gdpr: currentCompany.link_gdpr,
                    link_website: currentCompany.link_website,
                    partita_iva: currentCompany.partita_iva,
                    numero_di_telefono: currentCompany.numero_di_telefono,
                    locations: currentCompany.locations.map(location => ({
                        email: location.email,
                        email_servizio: location.email_servizio,
                        partita_iva: location.partita_iva,
                        numero_di_telefono: location.numero_di_telefono,
                        citta: location.citta,
                        regione: location.regione,
                        inidirizzo: location.inidirizzo,
                        cap: location.cap,
                        opening_hours: location.opening_hours
                    }))
                };
                
                const cleanedData = Object.fromEntries(
                    Object.entries(updateData)
                        .filter(([_, v]) => v != null && v !== '')
                );
                
                console.log('Cleaned data being sent:', cleanedData);
                
                updatedCompany = await updateCompany(currentCompany.id, cleanedData);
                console.log('Update response:', updatedCompany);
                
                if (logoFile) {
                    try {
                        const logoResponse = await uploadLogo(currentCompany.id, logoFile);
                        updatedCompany = { ...updatedCompany, logo_url: logoResponse.logo_url };
                    } catch (logoError) {
                        console.error('Error uploading logo:', logoError);
                    }
                }

                setRows(prevRows => 
                    prevRows.map(row => 
                        row.id === updatedCompany.id ? updatedCompany : row
                    )
                );
                showSnackbar('Company updated successfully', 'success');
            } else {
                updatedCompany = await createCompany(currentCompany);
                
                if (logoFile) {
                    try {
                        const logoResponse = await uploadLogo(updatedCompany.id, logoFile);
                        updatedCompany = { ...updatedCompany, logo_url: logoResponse.logo_url };
                    } catch (logoError) {
                        console.error('Error uploading logo:', logoError);
                    }
                }

                setRows(prevRows => [...prevRows, updatedCompany]);
                showSnackbar('Company created successfully', 'success');
            }
            
            handleClose();
        } catch (error) {
            console.error('Error details:', error.response?.data);
            const errorMessage = error.response?.data?.message || 
                               error.response?.data?.error || 
                               error.message || 
                               'Error saving company';
            showSnackbar(errorMessage, 'error');
        } finally {
            setIsLoading(false);
        }
    }, [currentCompany, logoFile, validateForm, handleClose, showSnackbar]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setCurrentCompany(prevCompany => ({ ...prevCompany, [name]: value }));
    }, []);

    const handleLogoChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setLogoFile(file);
        }
    };

    const handleLocationChange = useCallback((index, field, value) => {
        setCurrentCompany(prevCompany => {
            const updatedLocations = [...prevCompany.locations];
            updatedLocations[index] = { ...updatedLocations[index], [field]: value };
            return { ...prevCompany, locations: updatedLocations };
        });
    }, []);

    const addLocation = useCallback(() => {
        setCurrentCompany(prevCompany => ({
            ...prevCompany,
            locations: [
                ...prevCompany.locations,
                { email: '', email_servizio: '', partita_iva: '', numero_di_telefono: '', citta: '', regione: '', inidirizzo: '', cap: '', opening_hours: '' }
            ]
        }));
    }, []);

    const removeLocation = useCallback((index) => {
        setCurrentCompany(prevCompany => ({
            ...prevCompany,
            locations: prevCompany.locations.filter((_, i) => i !== index)
        }));
    }, []);

    const columns = [
        { field: 'name', headerName: 'Nome', flex: 1 },
        { field: 'email', headerName: 'Email', flex: 1 },
        { field: 'partita_iva', headerName: 'Partita IVA', flex: 1 },
        { field: 'numero_di_telefono', headerName: 'Telefono', flex: 1 },
        {
            field: 'actions',
            headerName: 'Azioni',
            flex: 1,
            renderCell: (params) => (
                <Box>
                    <Tooltip title="Edit">
                        <IconButton 
                            onClick={() => handleEdit(params.row)}
                            disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
                        >
                            <Edit size={20} />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton 
                            onClick={() => handleDelete(params.row.id)}
                            disabled={currentUser !== 'superadmin'}
                        >
                            <Trash2 size={20} />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },
        {
            field: 'ai',
            headerName: 'Manage AI',
            flex: 1,
            renderCell: (params) => (
                <Tooltip title="Manage AI">
                    <IconButton 
                        onClick={() => handleOpenAIDialog(params.row.id)}
                        disabled={currentUser !== 'superadmin' && currentUser !== 'admin'}
                    >
                        <Bot size={20} />
                    </IconButton>
                </Tooltip>
            ),
        },
    ];

    const CompanyCard = ({ company, currentUser, onEdit }) => {
        if (!company) return null;

        return (
            <Grow in={true} timeout={1000}>
                <Box sx={{ 
                    bgcolor: 'background.paper', 
                    borderRadius: 4, 
                    overflow: 'hidden', 
                    boxShadow: 3, 
                    maxWidth: "100%", 
                    margin: 'auto',
                    position: 'relative',
                    '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                        height: 160,
                        background: 'linear-gradient(45deg, #3a86ff 30%, #ff006e 90%)',
                        zIndex: 0,
                    }
                }}>
                    <Box sx={{ 
                        height: 160, 
                        position: 'relative',
                        zIndex: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {company.logo_url ? (
                            <Zoom in={true} timeout={1500}>
                                <Box
                                    component="img"
                                    src={company.logo_url}
                                    alt={`${company.name} logo`}
                                    sx={{
                                        width: "50%",
                                        height: "50%",
                                        objectFit: 'contain',
                                        filter: 'drop-shadow(0px 4px 8px rgba(0,0,0,0.2))',
                                    }}
                                />
                            </Zoom>
                        ) : (
                            <Typography variant="h4" sx={{ color: 'white' }}>
                                {company.name.charAt(0)}
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ pt: 3, pb: 3, px: 3 }}>
                        <Typography variant="h5" align="center" gutterBottom>{company.name}</Typography>
                        <Typography variant="body2" align="center" color="text.secondary" paragraph>
                            {company.descrizione}
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, mt: 2 }}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Mail size={18} style={{ marginRight: 8 }} />
                                <Typography 
                                    variant="body2" 
                                    component="a" 
                                    href={`mailto:${company.email}`}
                                    sx={{ color: 'primary.main', textDecoration: 'none', '&:hover': { textDecoration: 'underline' } }}
                                >
                                    {company.email}
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Building size={18} style={{ marginRight: 8 }} />
                                <Typography variant="body2">P. IVA: {company.partita_iva}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Phone size={18} style={{ marginRight: 8 }} />
                                <Typography variant="body2">{company.numero_di_telefono}</Typography>
                            </Box>
                        </Box>
                        {company.locations && company.locations.length > 0 && (
                            <Accordion sx={{ mt: 3 }}>
                                <AccordionSummary expandIcon={<ChevronDown />}>
                                    <Typography>Locations ({company.locations.length})</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <List>
                                        {company.locations.map((location, index) => (
                                            <ListItem key={index}>
                                                <ListItemText
                                                    primary={`${location.citta}, ${location.regione}`}
                                                    secondary={
                                                        <>
                                                            <Typography component="span" variant="body2" color="text.primary">
                                                                {location.inidirizzo}, {location.cap}
                                                            </Typography>
                                                            <br />
                                                            {location.email}
                                                        </>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                    </List>
                                </AccordionDetails>
                            </Accordion>
                        )}
                        {currentUser === 'admin' && (
                            <Button
                                variant="contained"
                                onClick={() => onEdit(company)}
                                startIcon={<Edit />}
                                fullWidth
                                sx={{ mt: 3 }}
                            >
                                Modifica Azienda
                            </Button>
                        )}
                    </Box>
                </Box>
            </Grow>
        );
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ minHeight: '100vh', bgcolor: 'background.default', py: { xs: 4, md: 10 }, px: { xs: 2, md: 6 } }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Fade in={true} timeout={1000}>
                            <Typography variant="h2" gutterBottom>{currentUser === 'superadmin' ? 'Concessionari' : 'Il tuo Concessionario'}</Typography>
                        </Fade>
                        {currentUser === 'superadmin' && (
                            <Fade in={true} timeout={1500}>
                                <Typography variant="subtitle1" gutterBottom>
                                    <Box component="span" sx={{ color: 'success.main', fontWeight: 'bold' }}>{rows.length}</Box> Aziende totali
                                </Typography>
                            </Fade>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        {currentUser === 'superadmin' && (
                            <Zoom in={true} timeout={1000}>
                                <Button 
                                    variant="contained" 
                                    color="primary" 
                                    onClick={handleAdd} 
                                    startIcon={<Plus />}
                                    sx={{ mb: 3 }}
                                >
                                    Aggiungi Azienda
                                </Button>
                            </Zoom>
                        )}
                        
                        {isLoading ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                                <CircularProgress />
                            </Box>
                        ) : currentUser === 'superadmin' ? (
                            <Fade in={true} timeout={1500}>
                                <Paper elevation={3} sx={{ borderRadius: 2, overflow: 'hidden' }}>
                                    <DataGrid 
                                        rows={rows} 
                                        columns={columns} 
                                        pageSize={5} 
                                        autoHeight
                                        disableSelectionOnClick
                                        sx={{ 
                                            '& .MuiDataGrid-cell:focus': {
                                                outline: 'none',
                                            },
                                        }} 
                                    />
                                </Paper>
                            </Fade>
                        ) : (
                            rows.length > 0 && (
                                <CompanyCard 
                                    company={rows[0]} 
                                    currentUser={currentUser} 
                                    onEdit={handleEdit}
                                />
                            )
                        )}
                    </Grid>
                </Grid>
            </Box>

            <Dialog 
                open={open} 
                onClose={handleClose} 
                maxWidth="md" 
                fullWidth
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 500 }}
            >
                <DialogTitle>{currentCompany.id ? 'Modifica Azienda' : 'Aggiungi Azienda'}</DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{ mb: 2 }}>
                        Compila i campi sottostanti per {currentCompany.id ? 'modificare' : 'aggiungere'} un'azienda.
                    </DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoFocus
                                margin="dense"
                                name="name"
                                label="Nome"
                                type="text"
                                fullWidth
                                value={currentCompany.name}
                                onChange={handleInputChange}
                                error={!!validationErrors.name}
                                helperText={validationErrors.name}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="email"
                                label="Email"
                                type="email"
                                fullWidth
                                value={currentCompany.email}
                                onChange={handleInputChange}
                                error={!!validationErrors.email}
                                helperText={validationErrors.email}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="email_servizio"
                                label="Email Servizio"
                                type="email"
                                fullWidth
                                value={currentCompany.email_servizio}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="partita_iva"
                                label="Partita IVA"
                                type="text"
                                fullWidth
                                value={currentCompany.partita_iva}
                                onChange={handleInputChange}
                                error={!!validationErrors.partita_iva}
                                helperText={validationErrors.partita_iva}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="numero_di_telefono"
                                label="Numero di Telefono"
                                type="tel"
                                fullWidth
                                value={currentCompany.numero_di_telefono}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                margin="dense"
                                name="link_gdpr"
                                label="Link GDPR"
                                type="url"
                                fullWidth
                                value={currentCompany.link_gdpr}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="dense"
                                name="link_website"
                                label="Link GDPR"
                                type="url"
                                fullWidth
                                value={currentCompany.link_website}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                margin="dense"
                                name="codice_concessionario"
                                label="Codice concessionario"
                                type="text"
                                fullWidth
                                value={currentCompany.codice_concessionario}
                                onChange={handleInputChange}
                            />
                            <TextField
                                margin="dense"
                                name="descrizione"
                                label="Descrizione"
                                type="text"
                                fullWidth
                                multiline
                                rows={4}
                                value={currentCompany.descrizione}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                type="file"
                                onChange={handleLogoChange}
                                sx={{ display: 'none' }}
                                id="logo-upload"
                            />
                             <Input
                                type="file"
                                onChange={handleLogoChange}
                                sx={{ display: 'none' }}
                                id="logo-upload"
                            />
                            <label htmlFor="logo-upload">
                                <Button
                                    variant="outlined"
                                    component="span"
                                    startIcon={<FileUpIcon />}
                                >
                                    {currentCompany.logo_url ? 'Cambia Logo' : 'Upload Logo'}
                                </Button>
                            </label>
                            {logoFile && (
                                <Typography variant="body2" sx={{ mt: 1 }}>
                                    Nuovo file selezionato: {logoFile.name}
                                </Typography>
                            )}
                            {currentCompany.logo_url && (
                                <Box sx={{ mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <Typography variant="subtitle1" gutterBottom>Logo attuale:</Typography>
                                    <Box
                                        component="img"
                                        src={currentCompany.logo_url}
                                        alt={`${currentCompany.name} logo`}
                                        sx={{
                                            width: 150,
                                            height: 150,
                                            objectFit: 'contain',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            padding: '8px',
                                        }}
                                    />
                                </Box>
                            )}
                        </Grid>
                    </Grid>

                    <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>Locations</Typography>
                    {currentCompany.locations.map((location, index) => (
                        <Accordion key={index} sx={{ mb: 2 }}>
                            <AccordionSummary expandIcon={<ChevronDown />}>
                                <Typography>Location {index + 1}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Città"
                                            value={location.citta}
                                            onChange={(e) => handleLocationChange(index, 'citta', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Regione"
                                            value={location.regione}
                                            onChange={(e) => handleLocationChange(index, 'regione', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Indirizzo"
                                            value={location.inidirizzo}
                                            onChange={(e) => handleLocationChange(index, 'inidirizzo', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="CAP"
                                            value={location.cap}
                                            onChange={(e) => handleLocationChange(index, 'cap', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Email"
                                            type="email"
                                            value={location.email}
                                            onChange={(e) => handleLocationChange(index, 'email', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Email Servizio"
                                            type="email"
                                            value={location.email_servizio}
                                            onChange={(e) => handleLocationChange(index, 'email_servizio', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Partita IVA"
                                            value={location.partita_iva}
                                            onChange={(e) => handleLocationChange(index, 'partita_iva', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            label="Numero di Telefono"
                                            value={location.numero_di_telefono}
                                            onChange={(e) => handleLocationChange(index, 'numero_di_telefono', e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Orari di Apertura"
                                            multiline
                                            rows={4}
                                            value={location.opening_hours}
                                            onChange={(e) => handleLocationChange(index, 'opening_hours', e.target.value)}
                                        />
                                    </Grid>
                                </Grid>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => removeLocation(index)}
                                    startIcon={<Trash2 />}
                                    sx={{ mt: 2 }}
                                >
                                    Rimuovi Location
                                </Button>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                    <Button
                        variant="outlined"
                        onClick={addLocation}
                        startIcon={<Plus />}
                        sx={{ mt: 2 }}
                    >
                        Aggiungi Location
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} startIcon={<X />}>
                        Annulla
                    </Button>
                    <Button onClick={handleSave} variant="contained" color="primary" startIcon={<Plus />}>
                        {currentCompany.id ? 'Aggiorna' : 'Salva'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog 
                open={confirmDeleteOpen} 
                onClose={() => setConfirmDeleteOpen(false)}
                PaperProps={{
                    style: {
                        borderRadius: '16px',
                    },
                }}
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 500 }}
            >
                <DialogTitle>Conferma eliminazione</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Sei sicuro di voler eliminare questa azienda? Questa azione non può essere annullata.
                    </DialogContentText>
                    </DialogContent>
                <DialogActions>
                    <Button onClick={() => setConfirmDeleteOpen(false)} startIcon={<X />}>
                        Annulla
                    </Button>
                    <Button onClick={confirmDelete} color="error" variant="contained" startIcon={<Trash2 />}>
                        Conferma
                    </Button>
                </DialogActions>
            </Dialog>

            <AIManagement
                open={openAIDialog}
                onClose={handleCloseAIDialog}
                companyId={selectedCompanyId}
                showSnackbar={showSnackbar}
            />

            <Snackbar 
                open={snackbar.open} 
                autoHideDuration={6000} 
                onClose={() => setSnackbar({ ...snackbar, open: false })}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            >
                <Alert 
                    onClose={() => setSnackbar({ ...snackbar, open: false })} 
                    severity={snackbar.severity} 
                    sx={{ width: '100%' }}
                    icon={snackbar.severity === 'success' ? <Info /> : undefined}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </ThemeProvider>
    );
};

export default PaginaAziende;