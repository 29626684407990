import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  TextField,
  MenuItem,
  Chip,
  Box,
  Divider,
  CircularProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@mui/material';
import { Phone, Email, Person, WhatsApp } from '@mui/icons-material';
import moment from 'moment';
import 'moment/locale/it';
import axios from 'axios';
import { useAuth } from '../contexts/AuthContext'; // Import useAuth hook for permissions

moment.locale('it');

const BookingDetailsDialog = ({ open, selectedBooking, onClose, onStatusChange }) => {
  const { userRole, appAccess } = useAuth(); // Get user role and app access
  const permissions = appAccess['booking'] || {}; // Extract booking-related permissions
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Only superadmin or users with edit access can change status

  const [newStatus, setNewStatus] = useState('');
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [notificationChannel, setNotificationChannel] = useState('');

  const translateStatus = (status) => {
    const statusTranslations = {
      'pending': 'In Attesa',
      'confirmed': 'Confermato',
      'cancelled': 'Annullato'
    };
    return statusTranslations[status] || status;
  };

  useEffect(() => {
    if (selectedBooking && selectedBooking.resource) {
      const { resource, title, start, end } = selectedBooking;
      const { client_name, last_name, status, whatsapp, email } = resource;
      setNewStatus(status);
      fetchTemplates(status, { title, start, end, client_name, last_name });

      // Set initial notification channel
      if (whatsapp && email) {
        setNotificationChannel('email'); // Default to email if both are available
      } else if (whatsapp) {
        setNotificationChannel('whatsapp');
      } else if (email) {
        setNotificationChannel('email');
      }
    }
  }, [selectedBooking]);

  const fetchTemplates = async (status, bookingDetails) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get('https://stage-admin.aitomotivelab.com/booking/email-templates/', {
        params: {
          service: selectedBooking.resource.service_id
        }
      });

      if (response.data && response.data.length > 0) {
        setTemplates(response.data);
        const template = response.data.find(t => t.status === status && t.channel === notificationChannel) || response.data[0];
        const populatedTemplate = populateTemplate(template, bookingDetails);
        setSubject(populatedTemplate.subject);
        setBody(populatedTemplate.body);
      } else {
        updateNotificationContent(status, bookingDetails);
      }
    } catch (error) {
      console.error('Error fetching templates:', error);
      setError('Failed to fetch templates. Using default template.');
      updateNotificationContent(status, bookingDetails);
    } finally {
      setIsLoading(false);
    }
  };

  const populateTemplate = (template, bookingDetails) => {
    const { title, start, end, client_name, last_name } = bookingDetails;
    const translatedStatus = translateStatus(template.status);

    let subject = template.subject;
    let body = template.body;

    const replacements = {
      '{{service_name}}': title,
      '{{customer_name}}': `${client_name} ${last_name}`,
      '{{appointment_date}}': moment(start).format('D MMMM YYYY'),
      '{{appointment_time}}': moment(start).format('HH:mm'),
      '{{status}}': translatedStatus,
      '{{start_time}}': moment(start).format('D MMMM YYYY, HH:mm'),
      '{{end_time}}': moment(end).format('D MMMM YYYY, HH:mm'),
    };

    Object.entries(replacements).forEach(([key, value]) => {
      subject = subject.replace(new RegExp(key, 'g'), value);
      body = body.replace(new RegExp(key, 'g'), value);
    });

    return { subject, body };
  };

  const updateNotificationContent = (status, bookingDetails) => {
    const { title, start, end, client_name, last_name } = bookingDetails;
    const translatedStatus = translateStatus(status);

    setSubject(`Aggiornamento Stato Prenotazione: ${title}`);
    setBody(`Gentile ${client_name} ${last_name},

La sua prenotazione per "${title}" è stata aggiornata a ${translatedStatus}.

Inizio: ${moment(start).format('D MMMM YYYY, HH:mm')}
Fine: ${moment(end).format('D MMMM YYYY, HH:mm')}

Se ha domande, non esiti a contattarci.`);
  };

  if (!selectedBooking || !selectedBooking.resource) {
    return null;
  }

  const { resource, title, start, end, notes } = selectedBooking;
  const { client_name, last_name, number, email, status, whatsapp, additional_information_answers } = resource;

  const handleStatusChange = (value) => {
    setNewStatus(value);
    const template = templates.find(t => t.status === value && t.channel === notificationChannel);
    if (template) {
      const populatedTemplate = populateTemplate(template, { title, start, end, client_name, last_name });
      setSubject(populatedTemplate.subject);
      setBody(populatedTemplate.body);
    } else {
      updateNotificationContent(value, { title, start, end, client_name, last_name });
    }
  };

  const handleConfirmStatusChange = () => {
    onStatusChange(newStatus, subject, body, notificationChannel);
    setConfirmDialogOpen(false);
  };

  const handleSave = () => {
    if (newStatus !== status) {
      setConfirmDialogOpen(true);
    } else {
      onClose();
    }
  };

  const handleNotificationChannelChange = (event) => {
    setNotificationChannel(event.target.value);
    // Fetch templates for the new channel
    fetchTemplates(newStatus, { title, start, end, client_name, last_name });
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle sx={{ backgroundColor: '#2196f3', color: '#fff' }}>
          Dettagli Prenotazione
        </DialogTitle>
        <DialogContent sx={{ padding: '24px' }}>
          {/* Titolo */}
          <Typography variant="h4" gutterBottom sx={{ fontWeight: 'bold', color: '#424242' }}>
            {title}
          </Typography>

          {/* Informazioni Cliente */}
          <Box display="flex" alignItems="center" gap={1} mb={2}>
            <Person color="action" />
            <Typography variant="body1" sx={{ color: '#424242' }}>
              Cliente: {client_name} {last_name}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap={1} mb={2}>
            <Phone color="action" />
            <Typography variant="body1" sx={{ color: '#424242' }}>
              Numero di telefono: {number}
            </Typography>
          </Box>
          {email && (
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <Email color="action" />
              <Typography variant="body1" sx={{ color: '#424242' }}>
                Email: {email}
              </Typography>
            </Box>
          )}
          {whatsapp && (
            <Box display="flex" alignItems="center" gap={1} mb={2}>
              <WhatsApp color="action" />
              <Typography disabled='True' variant="body1" sx={{ color: '#424242' }}>
                WhatsApp: Disponibile
              </Typography>
            </Box>
          )}

          {/* Note */}
          <Typography variant="body1" sx={{ color: '#424242' }}>
            Note: {notes || 'Nessuna nota disponibile'}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Orario Prenotazione */}
          <Typography variant="body1" sx={{ color: '#424242' }}>
            Inizio: {moment(start).format('D MMMM YYYY, HH:mm')}
          </Typography>
          <Typography variant="body1" sx={{ color: '#424242' }}>
            Fine: {moment(end).format('D MMMM YYYY, HH:mm')}
          </Typography>
          <Divider sx={{ my: 2 }} />

          {/* Stato */}
          <Typography
            variant="body1"
            gutterBottom
            sx={{ display: 'flex', alignItems: 'center', color: '#424242' }}
          >
            Stato Attuale:&nbsp;
            <Chip label={translateStatus(status)} color="primary" sx={{ fontSize: '0.875rem' }} />
          </Typography>

          {/* Informazioni Aggiuntive */}
          {additional_information_answers && additional_information_answers.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography
                variant="h6"
                gutterBottom
                sx={{ fontWeight: 'bold', color: '#424242' }}
              >
                Informazioni Aggiuntive
              </Typography>
              {additional_information_answers.map((info, index) => (
                <Box key={index} mb={2}>
                  <Typography variant="body1" sx={{ color: '#424242', fontWeight: 'bold' }}>
                    {info.add_inf.name}
                  </Typography>
                  
                  <Typography variant="body1" sx={{ color: '#424242', marginTop: '8px' }}>
                    {info.description}
                  </Typography>
                </Box>
              ))}
            </>
          )}

          {/* Cambio Stato - Only show if the user can edit */}
          {canEdit && (
            <Box mt={2}>
              <TextField
                select
                label="Cambia Stato"
                value={newStatus}
                onChange={(e) => handleStatusChange(e.target.value)}
                fullWidth
                variant="outlined"
              >
                <MenuItem value="pending">In Attesa</MenuItem>
                <MenuItem value="confirmed">Confermato</MenuItem>
                <MenuItem value="cancelled">Annullato</MenuItem>
              </TextField>
            </Box>
          )}

          {/* Selezione Canale di Notifica */}
          {(whatsapp || email) && canEdit && (
            <Box mt={2}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Canale di Notifica</FormLabel>
                <RadioGroup
                  aria-label="notification-channel"
                  name="notification-channel"
                  value={notificationChannel}
                  onChange={handleNotificationChannelChange}
                >
                  {email && <FormControlLabel value="email" control={<Radio />} label="Email" />}
                  {whatsapp && <FormControlLabel value="whatsapp" disabled='True' control={<Radio />} label="WhatsApp" />}
                </RadioGroup>
              </FormControl>
            </Box>
          )}

          {/* Campi Notifica */}
          {canEdit && (
            <Box mt={2}>
              <Typography variant="h6" gutterBottom>
                Contenuto Notifica
              </Typography>
              {isLoading ? (
                <CircularProgress />
              ) : (
                <>
                  {error && (
                    <Typography color="error" gutterBottom>
                      {error}
                    </Typography>
                  )}
                  {notificationChannel === 'email' && (
                    <TextField
                      label="Oggetto Email"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      fullWidth
                      margin="normal"
                    />
                  )}
                  <TextField
                    label={notificationChannel === 'email' ? "Corpo Email" : "Messaggio WhatsApp"}
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                    fullWidth
                    multiline
                    rows={6}
                    margin="normal"
                  />
                </>
              )}
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ padding: '16px' }}>
          <Button onClick={onClose} color="primary">
            Annulla
          </Button>
          {canEdit && (
            <Button onClick={handleSave} color="primary" variant="contained">
              Salva Modifiche
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {/* Dialogo di Conferma */}
      {canEdit && (
        <Dialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)}>
          <DialogTitle>Conferma Cambio Stato</DialogTitle>
          <DialogContent>
            <Typography>
              Sei sicuro di voler cambiare lo stato in {translateStatus(newStatus)}?
            </Typography>
            <Typography>
              Notifica verrà inviata via: {notificationChannel === 'email' ? 'Email' : 'WhatsApp'}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmDialogOpen(false)} color="primary">
              Annulla
            </Button>
            <Button onClick={handleConfirmStatusChange} color="primary" variant="contained">
              Conferma
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default BookingDetailsDialog;
