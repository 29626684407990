import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Box } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';

const ServiceList = ({ services, handleEditService, handleDeleteService }) => {
  const { userRole, appAccess } = useAuth();
  const permissions = appAccess['booking'] || {};
  const canEdit = permissions.can_edit || userRole === 'superadmin';
  const canDelete = permissions.can_delete || userRole === 'superadmin';

  const handleEdit = (service) => {
    const formattedService = {
      id: service.id,
      name: service.name || '',
      description: service.description || '',
      duration: service.duration || '',
      price: service.price?.toString() || '0',
      color: service.color || '#FFFFFF'
    };
    handleEditService(formattedService);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Duration</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Color</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {services.map((service) => (
            <TableRow key={service.id}>
              <TableCell>{service.name}</TableCell>
              <TableCell>{service.description}</TableCell>
              <TableCell>{service.duration}</TableCell>
              <TableCell>{service.price}</TableCell>
              <TableCell>
                <Box
                  sx={{
                    width: 30,
                    height: 30,
                    backgroundColor: service.color,
                    border: '1px solid #000',
                    borderRadius: '4px',
                  }}
                />
              </TableCell>
              <TableCell>
                {canEdit && (
                  <IconButton onClick={() => handleEdit(service)} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleDeleteService(service.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ServiceList;
