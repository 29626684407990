import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { Delete, AddCircleOutline } from '@mui/icons-material';

const conditionTypes = [
  { value: 'equals', label: 'Equals' },
  { value: 'notEquals', label: 'Not Equals' },
  { value: 'greaterThan', label: 'Greater Than' },
  { value: 'lessThan', label: 'Less Than' },
  { value: 'includes', label: 'Includes' },
  { value: 'apiValue', label: 'API Value' },
];

const fetchApiData = async (endpoint) => {
  // Mock API call
  const mockData = {
    'api/age': { value: 25 },
    'api/status': { value: 'active' },
  };
  return new Promise((resolve) => {
    setTimeout(() => resolve(mockData[endpoint] || { value: null }), 1000);
  });
};

const ConditionNode = ({ node, fields, pages, onChange, onDelete, onAdd, showNextPage, showApiCondition }) => {
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    if (node.type === 'apiValue' && node.apiEndpoint) {
      fetchApiData(node.apiEndpoint).then((data) => setApiData(data.value));
    }
  }, [node.type, node.apiEndpoint]);

  const handleChange = (key, value) => {
    onChange({ ...node, [key]: value });
  };

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <FormControl fullWidth margin="dense">
        <InputLabel>Field</InputLabel>
        <Select
          value={node.fieldId || ''}
          onChange={(e) => handleChange('fieldId', e.target.value)}
        >
          {fields.map((field) => (
            <MenuItem key={field.field_id} value={field.field_id}>
              {field.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl fullWidth margin="dense">
        <InputLabel>Condition Type</InputLabel>
        <Select
          value={node.type || ''}
          onChange={(e) => handleChange('type', e.target.value)}
        >
          {conditionTypes.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {node.type === 'apiValue' && showApiCondition ? (
        <TextField
          margin="dense"
          label="API Endpoint"
          type="text"
          fullWidth
          value={node.apiEndpoint || ''}
          onChange={(e) => handleChange('apiEndpoint', e.target.value)}
        />
      ) : (
        <TextField
          margin="dense"
          label="Value"
          type="text"
          fullWidth
          value={node.value || ''}
          onChange={(e) => handleChange('value', e.target.value)}
        />
      )}
      {showNextPage && (
        <FormControl fullWidth margin="dense">
          <InputLabel>Next Page</InputLabel>
          <Select
            value={node.next_page_id || ''}
            onChange={(e) => handleChange('next_page_id', e.target.value)}
          >
            <MenuItem value="">None</MenuItem>
            {pages.map((page) => (
              <MenuItem key={page.page_id} value={page.page_id}>
                {page.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <IconButton onClick={onDelete}>
        <Delete />
      </IconButton>
      <IconButton onClick={onAdd}>
        <AddCircleOutline />
      </IconButton>
    </Box>
  );
};

export default ConditionNode;