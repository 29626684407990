import React from 'react';
import { Grid, TextField, Button, FormControl, Select, MenuItem, InputLabel, Paper, Typography } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
const AvailabilityForm = ({
  availabilityFormData,
  handleAvailabilityFormChange,
  handleAvailabilityFormSubmit,
  isEditingAvailability,
  dayOfWeekOptions,
}) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this is related to booking
  const canCreate = permissions.can_create || userRole === 'superadmin'; // Create permission
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission

  // Determine if the user has permission to either create or edit
  const canSubmitForm = isEditingAvailability ? canEdit : canCreate;

  return (
    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
      <Typography variant="h6" gutterBottom>
        {isEditingAvailability ? 'Edit Availability' : 'Add New Availability'}
      </Typography>
      <form onSubmit={handleAvailabilityFormSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="day-of-week-label">Day of Week</InputLabel>
              <Select
                labelId="day-of-week-label"
                name="day_of_week"
                value={availabilityFormData.day_of_week}
                onChange={handleAvailabilityFormChange}
                required
                disabled={!canSubmitForm} // Disable if no permission
              >
                {dayOfWeekOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="start_time"
              label="Start Time"
              type="time"
              value={availabilityFormData.start_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="end_time"
              label="End Time"
              type="time"
              value={availabilityFormData.end_time}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              name="max_slots"
              label="Max Slots"
              type="number"
              value={availabilityFormData.max_slots}
              onChange={handleAvailabilityFormChange}
              fullWidth
              required
              disabled={!canSubmitForm} // Disable if no permission
            />
          </Grid>
          <Grid item xs={12}>
            {canSubmitForm && ( // Show the button only if the user has permission to submit
              <Button type="submit" variant="contained" color="primary">
                {isEditingAvailability ? 'Update Availability' : 'Add Availability'}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Paper>
  );
};

export default AvailabilityForm;
