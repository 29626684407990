import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, TextField, FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';
import { createUser, updateUser, fetchRoles } from '../api/user';

const AddEditUserDialog = ({ open, handleClose, currentRow, setCurrentRow, availableRoles, rows, setRows, error, setError }) => {
    const [accessibilities, setAccessibilities] = useState([]);
    console.log(rows)
    useEffect(() => {
        fetchRoles().then(setAccessibilities).catch(console.error);
    }, []);

    const validateEmail = (email) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(String(email).toLowerCase());
    };

    const handleSave = async () => {
        if (!validateEmail(currentRow.email)) {
            setError('Invalid email address');
            return;
        }

        if (!currentRow.accessibility) {
            setError('Accessibility is required');
            return;
        }

        try {
            const username = currentRow.email; // Set username to be the same as email
            const password = currentRow.id ? currentRow.password : Math.random().toString(36).substr(2, 8);
            const userData = {
                ...currentRow,
                username,
                password,
            };

            if (currentRow.id) {
                const updatedUser = await updateUser(currentRow.id, userData);
                setRows(rows.map((row) => (row.id === updatedUser.id ? updatedUser : row)));
            } else {
                const newUser = await createUser(userData);
                setRows([...rows, newUser]);
            }
            handleClose();
        } catch (error) {
            console.error('Error saving user:', error);
            setError('Error saving user. Please try again.');
        }
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{currentRow?.id ? 'Modifica Utente' : 'Aggiungi Utente'}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Compila i campi sottostanti per {currentRow?.id ? 'modificare' : 'aggiungere'} un utente.
                </DialogContentText>
                {error && <Typography color="error">{error}</Typography>}
                <TextField
                    autoFocus
                    margin="dense"
                    label="Nome"
                    type="text"
                    fullWidth
                    value={currentRow?.first_name || ''}
                    onChange={(e) => setCurrentRow({ ...currentRow, first_name: e.target.value })}
                />
                <TextField
                    margin="dense"
                    label="Cognome"
                    type="text"
                    fullWidth
                    value={currentRow?.last_name || ''}
                    onChange={(e) => setCurrentRow({ ...currentRow, last_name: e.target.value })}
                />
               <div style={{ display: 'none' }}>
                    <TextField
                        margin="dense"
                        label="Telefono"
                        type="text"
                        fullWidth
                        value={currentRow?.phone || ''}
                        onChange={(e) => setCurrentRow({ ...currentRow, phone: e.target.value })}
                    />
                </div>
                <TextField
                    margin="dense"
                    label="Email"
                    type="email"
                    fullWidth
                    value={currentRow?.email || ''}
                    onChange={(e) => setCurrentRow({ ...currentRow, email: e.target.value, username: e.target.value })}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="role-label">Ruolo</InputLabel>
                    <Select
                        labelId="role-label"
                        value={currentRow?.role || ''}
                        label="Ruolo"
                        onChange={(e) => setCurrentRow({ ...currentRow, role: e.target.value })}
                    >
                        {availableRoles.map((role) => (
                            <MenuItem key={role} value={role}>
                                {role.charAt(0).toUpperCase() + role.slice(1).replace('_', ' ')}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {currentRow?.id && (
                    <TextField
                        margin="dense"
                        label="Azienda Primaria"
                        type="text"
                        fullWidth
                        value={currentRow?.primary_company?.name || ''}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                )}
                <FormControl fullWidth margin="dense">
                    <InputLabel id="accessibility-label">Accessibilità</InputLabel>
                    <Select
                        labelId="accessibility-label"
                        value={currentRow?.accessibility || ''}
                        label="Accessibilità"
                        onChange={(e) => setCurrentRow({ ...currentRow, accessibility: e.target.value })}
                    >
                        {accessibilities.map((accessibility) => (
                            <MenuItem key={accessibility.id} value={accessibility.id}>
                                {accessibility.role_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Annulla
                </Button>
                <Button onClick={handleSave} color="primary">
                    Salva
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AddEditUserDialog;