import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Delete as DeleteIcon, Edit as EditIcon } from '@mui/icons-material';
import { useAuth } from '../../../contexts/AuthContext';
const AddtionalInformationList = ({
  addtionalInformation,
  handleEditAddtionalInformation,
  handleDeleteAddtionalInformation,
}) => {
  const { userRole, appAccess } = useAuth(); // Access user role and permissions
  const permissions = appAccess['booking'] || {}; // Assuming this is related to booking
  const canEdit = permissions.can_edit || userRole === 'superadmin'; // Edit permission
  const canDelete = permissions.can_delete || userRole === 'superadmin'; // Delete permission

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {addtionalInformation.map((info) => (
            <TableRow key={info.id}>
              <TableCell>{info.name}</TableCell>
              <TableCell>{info.description}</TableCell>
              <TableCell>
                {canEdit && (
                  <IconButton onClick={() => handleEditAddtionalInformation(info)} color="primary">
                    <EditIcon />
                  </IconButton>
                )}
                {canDelete && (
                  <IconButton onClick={() => handleDeleteAddtionalInformation(info.id)} color="error">
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AddtionalInformationList;
