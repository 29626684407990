import React, { useState, useEffect, useCallback } from 'react';
import { 
  TextField, Button, Box, Typography, Grid, Card, CardContent,
  Checkbox, FormControlLabel, Pagination, CircularProgress, Snackbar, 
  Alert, Select, MenuItem
} from '@mui/material';
import { Search } from '@mui/icons-material';
import axios from 'axios';

const API_BASE_URL = 'https://stage-admin.aitomotivelab.com/car_stock/api';

const CarSearchPage = ({ onSelectCars, initialSelectedCars = [] }) => {
  const [carStocks, setCarStocks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedCars, setSelectedCars] = useState(initialSelectedCars);
  const [filters, setFilters] = useState({
    min_price: '',
    max_price: '',
    brand: '',
    fuel: '',
  });
  const [filterOptions, setFilterOptions] = useState({
    brands: [],
    fuel_types: [],
  });

  const fetchFilterOptions = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/carstocks/filter_options/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setFilterOptions(response.data);
    } catch (error) {
      console.error('Error fetching filter options:', error);
      setError('Failed to fetch filter options. Please refresh the page.');
    }
  }, []);

  const fetchCarStocks = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await axios.get(`${API_BASE_URL}/carstocks/`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        },
        params: {
          ...filters,
          search: searchTerm,
          page: page
        }
      });
      setCarStocks(response.data.results);
      setTotalPages(Math.ceil(response.data.count / response.data.results.length));
    } catch (error) {
      console.error('Error fetching car stocks:', error);
      setError('An error occurred while fetching car stocks. Please try again.');
      setCarStocks([]);
      setTotalPages(1);
    } finally {
      setLoading(false);
    }
  }, [filters, searchTerm, page]);

  useEffect(() => {
    fetchFilterOptions();
  }, [fetchFilterOptions]);

  useEffect(() => {
    fetchCarStocks();
  }, [fetchCarStocks]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(1);
  };

  const handleFilterChange = (event) => {
    setFilters({ ...filters, [event.target.name]: event.target.value });
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleCarSelection = (car) => {
    setSelectedCars(prevSelected => {
      const isAlreadySelected = prevSelected.some(selectedCar => selectedCar.vehicleid === car.vehicleid);
      if (isAlreadySelected) {
        return prevSelected.filter(selectedCar => selectedCar.vehicleid !== car.vehicleid);
      } else {
        return [...prevSelected, car];
      }
    });
  };

  const handleConfirmSelection = () => {
    onSelectCars(selectedCars);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" gutterBottom>Select Cars</Typography>
      <Grid container spacing={2} sx={{ mb: 3 }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Search cars"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            InputProps={{
              startAdornment: <Search color="action" />,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            name="brand"
            value={filters.brand}
            onChange={handleFilterChange}
            displayEmpty
          >
            <MenuItem value="">All Brands</MenuItem>
            {filterOptions.brands.map((brand) => (
              <MenuItem key={brand} value={brand}>{brand}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Select
            fullWidth
            name="fuel"
            value={filters.fuel}
            onChange={handleFilterChange}
            displayEmpty
          >
            <MenuItem value="">All Fuel Types</MenuItem>
            {filterOptions.fuel_types.map((fuelType) => (
              <MenuItem key={fuelType} value={fuelType}>{fuelType}</MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Grid container spacing={2}>
            {carStocks.map((car) => (
              <Grid item xs={12} sm={6} md={4} key={car.vehicleid}>
                <Card>
                  <CardContent>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedCars.some(selectedCar => selectedCar.vehicleid === car.vehicleid)}
                          onChange={() => handleCarSelection(car)}
                        />
                      }
                      label={`${car.brand} ${car.model}`}
                    />
                    <Typography variant="body2" color="text.secondary">
                      {car.version} - {car.fuel}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {car.km?.toLocaleString()} km
                    </Typography>
                    <Typography variant="h6" color="primary">
                      €{car.saleprice?.toLocaleString()}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
          
          <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
            <Pagination 
              count={totalPages} 
              page={page} 
              onChange={handlePageChange} 
              color="primary" 
            />
          </Box>
        </>
      )}

      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error" sx={{ width: '100%' }}>
            {error}
          </Alert>
        </Snackbar>
      )}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmSelection}
          disabled={selectedCars.length === 0}
        >
          Confirm Selection ({selectedCars.length})
        </Button>
      </Box>
    </Box>
  );
};

export default CarSearchPage;