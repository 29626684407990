import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, MenuItem, Box, Button } from '@mui/material';

const ServiceDialog = ({ open, services, startDate, onStartDateChange, onClose, onServiceSelect, onNext }) => {
  const [selectedService, setSelectedService] = React.useState('');
  const [localStartDate, setLocalStartDate] = React.useState(startDate);

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
  };

  const handleNext = () => {
    onServiceSelect(selectedService, localStartDate);
    onNext();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Seleziona un servizio e un orario</DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <TextField
            select
            label="Servizio"
            value={selectedService}
            onChange={handleServiceChange}
            fullWidth
            margin="normal"
          >
            {services.map((service) => (
              <MenuItem key={service.id} value={service.id}>
                {service.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mb={2}>
          <TextField
            label="Data Inizio e Orario"
            type="datetime-local"
            value={localStartDate}
            onChange={(e) => setLocalStartDate(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Annulla</Button>
        <Button 
          onClick={handleNext}
          variant="contained" 
          color="primary"
          disabled={!selectedService || !localStartDate}
        >
          Avanti
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceDialog;
