// ChatColorSettings.js
import React from 'react';
import { 
    Grid, 
    Typography, 
    TextField,
    Tooltip,
    IconButton
} from '@mui/material';
import { HelpCircle } from 'lucide-react';

const ChatColorSettings = ({ colors = {}, onColorChange, onTextChange }) => {
    const handleColorChange = (colorKey) => (event) => {
        onColorChange(colorKey, event.target.value);
    };

    const handleTextChange = (event) => {
        const { name, value } = event.target;
        onTextChange(name, value);
    };

    const defaultColors = {
        primary_color: '#424bf5',
        secondary_color: '#b9c6ae',
        text_primary_color: '#f1faee',
        text_secondary_color: '#090c08',
        welcome_message: "Benvenuto sulla nostra chat, come posso aiutarti?",
        message_1: "Vorrei chiedere informazioni",
        message_2: "Vorrei fissare un appuntamento",
        message_3: "Vorrei cercare un veicolo disponibile",
    };

    const currentColors = { ...defaultColors, ...colors };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Chat Color Settings
                    <Tooltip title="Customize the colors and messages of your chat widget">
                        <IconButton size="small">
                            <HelpCircle size={18} />
                        </IconButton>
                    </Tooltip>
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="welcome_message"
                    label="Welcome message"
                    value={currentColors.welcome_message}
                    onChange={handleTextChange}
                    multiline
                    rows={3}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="message_1"
                    label="First button"
                    value={currentColors.message_1}
                    onChange={handleTextChange}
                    multiline
                    rows={3}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="message_2"
                    label="Second button"
                    value={currentColors.message_2}
                    onChange={handleTextChange}
                    multiline
                    rows={3}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    name="message_3"
                    label="Third button"
                    value={currentColors.message_3}
                    onChange={handleTextChange}
                    multiline
                    rows={3}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Primary Color"
                    type="color"
                    value={currentColors.primary_color}
                    onChange={handleColorChange('primary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Secondary Color"
                    type="color"
                    value={currentColors.secondary_color}
                    onChange={handleColorChange('secondary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Primary Text Color"
                    type="color"
                    value={currentColors.text_primary_color}
                    onChange={handleColorChange('text_primary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    label="Secondary Text Color"
                    type="color"
                    value={currentColors.text_secondary_color}
                    onChange={handleColorChange('text_secondary_color')}
                    InputProps={{ sx: { height: 56 } }}
                />
            </Grid>
        </Grid>
    );
};

export default ChatColorSettings;