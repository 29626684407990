import React from 'react';
import { 
  Drawer, 
  List, 
  ListItem, 
  ListItemIcon, 
  ListItemText, 
  Box, 
  Tooltip, 
  Divider, 
  Typography, 
  useMediaQuery, 
  useTheme, 
  IconButton,
  CircularProgress
} from '@mui/material';
import { 
  Home, 
  Server, 
  Users, 
  Settings, 
  Lock, 
  HelpCircle, 
  X, 
  Calendar, 
  MessageCircle 
} from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';

const Sidebar = ({ mobileOpen, handleDrawerToggle, isOpen, handleSidebarOpen, darkMode }) => {
  const { user, loading, appAccess } = useAuth();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const currentYear = new Date().getFullYear();

  const menuItems = [
    { text: 'Dashboard', icon: Home, path: '/', app: "Required" },
    { text: 'Calendario', icon: Calendar, path: '/booking', app: "booking" },
    { text: 'Clienti', icon: Users, path: '/clients', app: "crm_app" },
    { text: 'Chat', icon: MessageCircle, path: '/chats', app: "ai_app" },
    { text: 'Stock auto', icon: Server, path: '/car_stock', app: "car_stock" },
    { text: 'Impostazioni', icon: Settings, path: '/aziende', app: "settings" },
    { text: 'Utenti', icon: Users, path: '/utenti', app: "user_management" },
    { text: 'Ruoli', icon: Lock, path: '/ruoli', app: "user_management" },
    { text: 'Modulo di registrazione utenti', icon: Server, path: '/forms', app: "settings" },
  ];

  const filteredMenuItems = loading
    ? []
    : menuItems.filter(item => {
        if (item.app === "Required") return true;
        if (user && user.role === "superadmin") return true;
        if (!appAccess) return false;
        
        // Check if the app exists in appAccess and has can_view set to true
        return appAccess[item.app] && appAccess[item.app].can_view === true;
      });

  const renderMenuItem = (item, index) => {
    const isActive = location.pathname === item.path;

    return (
      <motion.div
        key={item.text}
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ delay: index * 0.1 }}
      >
        <Tooltip title={isOpen || isMobile ? '' : item.text} placement="right">
          <ListItem
            component={Link}
            to={item.path}
            onClick={isMobile ? handleDrawerToggle : undefined}
            sx={{
              minHeight: { xs: 40, sm: 48 },
              justifyContent: isOpen || isMobile ? 'initial' : 'center',
              px: { xs: 1.5, sm: 2.5 },
              borderRadius: '8px',
              mx: { xs: 0.5, sm: 1 },
              my: 0.5,
              backgroundColor: isActive ? (darkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(67, 97, 238, 0.1)') : 'transparent',
              color: isActive ? (darkMode ? '#FFFFFF' : '#4361EE') : (darkMode ? '#BBBBBB' : 'inherit'),
              '&:hover': {
                backgroundColor: darkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(67, 97, 238, 0.08)',
              },
              transition: 'all 0.3s',
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: isOpen || isMobile ? { xs: 2, sm: 3 } : 'auto',
                justifyContent: 'center',
                color: isActive ? (darkMode ? '#FFFFFF' : '#4361EE') : (darkMode ? '#BBBBBB' : 'inherit'),
              }}
            >
              <item.icon size={isMobile ? 20 : 24} />
            </ListItemIcon>
            <AnimatePresence>
              {(isOpen || isMobile) && (
                <motion.div
                  initial={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 'auto' }}
                  exit={{ opacity: 0, width: 0 }}
                  transition={{ duration: 0.3 }}
                >
                  <ListItemText
                    primary={item.text}
                    primaryTypographyProps={{
                      fontSize: 14,
                      fontWeight: isActive ? 'bold' : 'normal',
                    }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
          </ListItem>
        </Tooltip>
      </motion.div>
    );
  };

  const drawer = (
    <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {isMobile && (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
          <IconButton onClick={handleDrawerToggle} sx={{ color: darkMode ? '#FFFFFF' : 'inherit' }}>
            <X size={24} />
          </IconButton>
        </Box>
      )}
      <Divider sx={{ mb: 2, borderColor: darkMode ? 'rgba(255, 255, 255, 0.12)' : 'rgba(0, 0, 0, 0.12)' }} />
      <List sx={{ flexGrow: 1, px: 2 }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : (
          filteredMenuItems.map(renderMenuItem)
        )}
      </List>
      <Box sx={{ p: 2, textAlign: 'center' }}>
        <AnimatePresence>
          {(isOpen || isMobile) && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <Typography variant="caption" sx={{ color: darkMode ? '#BBBBBB' : 'inherit' }}>
                © {currentYear} Aitomotivelab.com
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );

  if (isMobile) {
    return (
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: { xs: '85%', sm: '60%' },
            maxWidth: '300px',
            backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
            color: darkMode ? '#FFFFFF' : 'inherit',
          },
        }}
      >
        {drawer}
      </Drawer>
    );
  }

  return (
    <Drawer
      variant="permanent"
      open={true}
      onMouseEnter={() => handleSidebarOpen(true)}
      onMouseLeave={() => handleSidebarOpen(false)}
      sx={{
        width: isOpen ? 240 : 73,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: isOpen ? 240 : 73,
          boxSizing: 'border-box',
          backgroundColor: darkMode ? '#1E1E1E' : '#FFFFFF',
          color: darkMode ? '#FFFFFF' : 'inherit',
          transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
          overflowX: 'hidden',
          border: 'none',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
        },
      }}
    >
      <Box sx={{ height: { xs: 64, md: 80 } }} /> {/* Offset for header */}
      {drawer}
    </Drawer>
  );
};

export default Sidebar;