import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';

const PropertyDialog = ({
  isOpen,
  onClose,
  property,
  setProperty,
  onSave,
  propertyGroups,
}) => {
  const isEditing = Boolean(property.property_id);

  const sanitizedProperty = useMemo(() => ({
    property_id: property.property_id || null,
    name: property.name || '',
    description: property.description || '',
    property_type: property.property_type || 'text',
    options: property.options || [],
    required: property.required || false,
    property_group_id: property.property_group_id || null,  // Changed from property_group_id
    property_group: property.property_group_id || null,  // Changed from property_group_id
  }), [property]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperty(prevProperty => ({
      ...prevProperty,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setProperty(prevProperty => ({
      ...prevProperty,
      [name]: checked,
    }));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>{isEditing ? 'Edit Property' : 'Add Property'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Property Name"
          name="name"
          type="text"
          fullWidth
          value={sanitizedProperty.name}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Property Description"
          name="description"
          type="text"
          fullWidth
          multiline
          rows={2}
          value={sanitizedProperty.description}
          onChange={handleChange}
        />
        <FormControl fullWidth margin="dense">
          <InputLabel>Property Type</InputLabel>
          <Select
            name="property_type"
            value={sanitizedProperty.property_type}
            onChange={handleChange}
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="boolean">Boolean</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="multi_select">Multi Select</MenuItem>
          </Select>
        </FormControl>
        {(sanitizedProperty.property_type === 'select' || sanitizedProperty.property_type === 'multi_select') && (
          <TextField
            margin="dense"
            label="Options (comma separated)"
            name="options"
            type="text"
            fullWidth
            value={sanitizedProperty.options.join(',')}
            onChange={(e) => setProperty(prevProperty => ({
              ...prevProperty,
              options: e.target.value.split(',').map(opt => opt.trim()),
            }))}
          />
        )}
        <FormControl fullWidth margin="dense">
          <InputLabel>Property Group</InputLabel>
          <Select
            name="property_group_id"
            value={sanitizedProperty.property_group_id}
            onChange={handleChange}
          >
            {Array.isArray(propertyGroups) && propertyGroups.map(group => (
              <MenuItem key={group.property_group_id} value={group.property_group_id}>
                {group.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControlLabel
          control={
            <Checkbox
              checked={sanitizedProperty.required}
              onChange={handleCheckboxChange}
              name="required"
            />
          }
          label="Required"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={() => onSave(sanitizedProperty)}>{isEditing ? 'Update' : 'Add'} Property</Button>
      </DialogActions>
    </Dialog>
  );
};

PropertyDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  property: PropTypes.shape({
    property_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    description: PropTypes.string,
    property_type: PropTypes.string,
    options: PropTypes.array,
    required: PropTypes.bool,
    property_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  setProperty: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  propertyGroups: PropTypes.arrayOf(PropTypes.shape({
    property_group_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
  })),
};

PropertyDialog.defaultProps = {
  property: {},
  propertyGroups: [],
};

export default PropertyDialog;