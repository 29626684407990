// ItalianLocationFields.js
import React, { useState, useEffect } from 'react';
import { TextField, Box, Autocomplete, CircularProgress } from '@mui/material';
import PropTypes from 'prop-types';

const BASE_URL = 'https://stage-admin.aitomotivelab.com';

const ItalianLocationFields = ({ onChange, disabled }) => {
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [municipalities, setMunicipalities] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState(null);

  const [loading, setLoading] = useState({
    regions: false,
    provinces: false,
    municipalities: false
  });

  useEffect(() => {
    setLoading(prev => ({ ...prev, regions: true }));
    fetch(`${BASE_URL}/location/regions/`)
      .then(response => response.json())
      .then(data => setRegions(data))
      .catch(err => console.error('Error fetching regions:', err))
      .finally(() => setLoading(prev => ({ ...prev, regions: false })));
  }, []);

  useEffect(() => {
    if (selectedRegion) {
      setLoading(prev => ({ ...prev, provinces: true }));
      fetch(`${BASE_URL}/location/provinces/?region=${selectedRegion}`)
        .then(response => response.json())
        .then(data => setProvinces(data))
        .catch(err => console.error('Error fetching provinces:', err))
        .finally(() => setLoading(prev => ({ ...prev, provinces: false })));
    } else {
      setProvinces([]);
      setMunicipalities([]);
      setSelectedProvince(null);
      setSelectedMunicipality(null);
    }
  }, [selectedRegion]);

  useEffect(() => {
    if (selectedProvince) {
      setLoading(prev => ({ ...prev, municipalities: true }));
      fetch(`${BASE_URL}/location/municipalities/?province=${selectedProvince}`)
        .then(response => response.json())
        .then(data => setMunicipalities(data))
        .catch(err => console.error('Error fetching municipalities:', err))
        .finally(() => setLoading(prev => ({ ...prev, municipalities: false })));
    } else {
      setMunicipalities([]);
      setSelectedMunicipality(null);
    }
  }, [selectedProvince]);

  const handleRegionChange = (event, value) => {
    setSelectedRegion(value);
    onChange('region', value);
  };

  const handleProvinceChange = (event, value) => {
    setSelectedProvince(value);
    onChange('province', value);
  };

  const handleMunicipalityChange = (event, value) => {
    setSelectedMunicipality(value);
    onChange('municipality', value);
  };

  return (
    <Box sx={{ '& > *': { mb: 2 } }}>
      <Autocomplete
        options={regions.map(region => region.name)}
        onChange={handleRegionChange}
        disabled={disabled}
        loading={loading.regions}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Region"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading.regions ? <CircularProgress size={24} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Autocomplete
        options={provinces.map(province => province.name)}
        onChange={handleProvinceChange}
        disabled={disabled || !selectedRegion}
        loading={loading.provinces}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Province"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading.provinces ? <CircularProgress size={24} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Autocomplete
        options={municipalities.map(municipality => municipality.name)}
        onChange={handleMunicipalityChange}
        disabled={disabled || !selectedProvince}
        loading={loading.municipalities}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Municipality"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading.municipalities ? <CircularProgress size={24} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </Box>
  );
};

ItalianLocationFields.propTypes = {
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default ItalianLocationFields;
