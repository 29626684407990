import React, { useState, useEffect, useCallback } from 'react';
import {
    Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, Select, MenuItem,
    Typography, Collapse, IconButton, Box,
    Divider, Paper, FormControlLabel, Switch
} from '@mui/material';
import { X, ChevronDown, ChevronUp, Bot, Zap, Copy } from 'lucide-react';
import { fetchAI, createOrUpdateAI } from '../api/ai';
import ChatColorSettings from './ChatColorSettings';

const defaultChatSettings = {
    primary_color: '#424bf5',
    secondary_color: '#b9c6ae',
    text_primary_color: '#f1faee',
    text_secondary_color: '#090c08',
    welcome_message: "Benvenuto sulla nostra chat, come posso aiutarti?",
    message_1: "Vorrei chiedere informazioni",
    message_2: "Vorrei fissare un appuntamento",
    message_3: "Vorrei cercare un veicolo disponibile",
};

const defaultAI = {
    name: '',
    comportamento: '',
    obbiettivo: '',
    limiti: '',
    telegram_key: '',
    numero_whatsapp: '',
    k_retrieve: 3,
    k_retrieve_messages: 2,
    temperature: 0.0,
    model: 'gpt-4o',
    chat_settings: defaultChatSettings,
    attiva: true
};

const AIManagement = ({ open, onClose, companyId, showSnackbar }) => {
    const [currentAI, setCurrentAI] = useState(defaultAI);
    const [isLoading, setIsLoading] = useState(true);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [scriptVisible, setScriptVisible] = useState(false);
    const [script, setScript] = useState('');

    useEffect(() => {
        if (open && companyId) {
            fetchCompanyAI();
        }
    }, [open, companyId]);

    const fetchCompanyAI = useCallback(async () => {
        setIsLoading(true);
        try {
            const ai = await fetchAI(companyId);
            console.log(ai)
            if (ai) {
                setCurrentAI({
                    ...ai,
                    chat_settings: { ...defaultChatSettings, ...ai.chat_settings },
                    attiva: ai.attiva !== undefined ? ai.attiva : true
                });
            } else {
                setCurrentAI(defaultAI);
            }
        } catch (error) {
            console.error('Error fetching AI:', error);
            showSnackbar('Error fetching AI', 'error');
            setCurrentAI(defaultAI);
        } finally {
            setIsLoading(false);
        }
    }, [companyId, showSnackbar]);

    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setCurrentAI(prevAI => ({ ...prevAI, [name]: value }));
    }, []);

    const handleSwitchChange = useCallback((e) => {
        const { name, checked } = e.target;
        setCurrentAI(prevAI => ({ ...prevAI, [name]: checked }));
    }, []);

    const handleColorChange = useCallback((colorKey, value) => {
        setCurrentAI(prevAI => ({
            ...prevAI,
            chat_settings: {
                ...prevAI.chat_settings,
                [colorKey]: value
            }
        }));
    }, []);

    const handleTextChange = useCallback((name, value) => {
        setCurrentAI(prevAI => ({
            ...prevAI,
            chat_settings: {
                ...prevAI.chat_settings,
                [name]: value
            }
        }));
    }, []);

    const generateScript = useCallback((aiToken) => {
        const scriptContent = `
            (function() {
                const CHAT_SCRIPT_URL = 'https://chat-aitomotive.pages.dev/chat-loader.js';
                const RETRY_DELAY = 3000; // 3 seconds
                const MAX_RETRIES = 3;

                let retryCount = 0;

                function loadChatScript() {
                    return new Promise((resolve, reject) => {
                        const script = document.createElement('script');
                        script.src = CHAT_SCRIPT_URL;
                        script.async = true;

                        script.onload = () => {
                            console.log('%cChat script loaded successfully! 🚀', 'color: #4CAF50; font-weight: bold;');
                            resolve();
                        };

                        script.onerror = () => {
                            reject(new Error('Failed to load chat script'));
                        };

                        document.body.appendChild(script);
                    });
                }

                function initializeChat() {
                    console.log('%cInitializing chat...', 'color: #2196F3; font-weight: bold;');
                    // Example: window.ChatApp.init('${aiToken}');
                }

                function handleError(error) {
                    console.error('%cError loading chat script:', 'color: #FF5722; font-weight: bold;', error);
                    
                    if (retryCount < MAX_RETRIES) {
                        retryCount++;
                        console.log(\`%cRetrying in \${RETRY_DELAY / 1000} seconds... (Attempt \${retryCount}/\${MAX_RETRIES})\`, 'color: #FFC107; font-style: italic;');
                        setTimeout(loadAndInitialize, RETRY_DELAY);
                    } else {
                        console.error('%cMax retries reached. Chat could not be loaded.', 'color: #FF5722; font-weight: bold;');
                    }
                }

                function loadAndInitialize() {
                    loadChatScript()
                        .then(() => {
                            initializeChat();
                        })
                        .catch(handleError);
                }

                if ('IntersectionObserver' in window) {
                    const observer = new IntersectionObserver((entries) => {
                        if (entries[0].isIntersecting) {
                            loadAndInitialize();
                            observer.disconnect();
                        }
                    });
                    observer.observe(document.getElementById('chat-root'));
                } else {
                    document.addEventListener('DOMContentLoaded', loadAndInitialize);
                }
            })();
        `;
        setScript(scriptContent);
        setScriptVisible(true);
    }, []);

    const handleSaveAI = useCallback(async () => {
        try {
            const aiDataToSave = {
                ...currentAI,
                chat_settings: currentAI.chat_settings || defaultChatSettings
            };
            const savedAI = await createOrUpdateAI(companyId, aiDataToSave);
            showSnackbar('AI saved successfully', 'success');
            generateScript(savedAI.api_token);
        } catch (error) {
            console.error('Error saving AI:', error);
            showSnackbar('Error saving AI', 'error');
        }
    }, [currentAI, companyId, showSnackbar, generateScript]);

    const handleCopyScript = useCallback(() => {
        navigator.clipboard.writeText(script).then(() => {
            showSnackbar('Script copied to clipboard', 'success');
        }, (err) => {
            console.error('Could not copy text: ', err);
            showSnackbar('Failed to copy script', 'error');
        });
    }, [script, showSnackbar]);

    if (isLoading) {
        return <Typography>Loading...</Typography>;
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                <Box display="flex" alignItems="center">
                    <Bot size={24} style={{ marginRight: 8 }} />
                    {currentAI.id ? 'Edit AI' : 'Create AI'}
                </Box>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="name"
                            label="AI Name"
                            value={currentAI.name}
                            onChange={handleInputChange}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={currentAI.attiva}
                                    onChange={handleSwitchChange}
                                    name="attiva"
                                    color="primary"
                                />
                            }
                            label="Active"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="comportamento"
                            label="Behavior"
                            value={currentAI.comportamento}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="obbiettivo"
                            label="Objective"
                            value={currentAI.obbiettivo}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            name="limiti"
                            label="Limitations"
                            value={currentAI.limiti}
                            onChange={handleInputChange}
                            multiline
                            rows={3}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                        <Box display="flex" justifyContent="space-between" alignItems="center" my={2}>
                            <Typography variant="h6">Advanced Options</Typography>
                            <IconButton onClick={() => setShowAdvanced(!showAdvanced)}>
                                {showAdvanced ? <ChevronUp /> : <ChevronDown />}
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
                <Collapse in={showAdvanced}>
                    <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
                        <Grid container spacing={3}>
                           
                            <Grid item xs={12}>
                                <Divider sx={{ my: 2 }} />
                                <ChatColorSettings 
                                    colors={currentAI.chat_settings}
                                    onColorChange={handleColorChange}
                                    onTextChange={handleTextChange}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Collapse>
                
                {scriptVisible && (
                    <Box mt={3}>
                        <Typography variant="h6" gutterBottom>
                            Website Integration Script
                        </Typography>
                        <Paper variant="outlined" sx={{ p: 2, position: 'relative' }}>
                            <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
                                {script}
                            </pre>
                            <IconButton
                                onClick={handleCopyScript}
                                sx={{ position: 'absolute', top: 8, right: 8 }}
                            >
                                <Copy />
                            </IconButton>
                        </Paper>
                        <Typography variant="body2" color="textSecondary" mt={1}>
                            Add this script to your website to integrate the chat functionality.
                        </Typography>
                    </Box>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} startIcon={<X />}>
                    Cancel
                </Button>
                <Button onClick={handleSaveAI} variant="contained" color="primary" startIcon={<Zap />}>
                    Save AI
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default AIManagement;