import React, { createContext, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [appAccess, setAppAccess] = useState({});
  const DOMAIN = process.env.REACT_APP_API_URL || 'https://stage-admin.aitomotivelab.com'
  const API_BASE_URL = DOMAIN
  
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      fetchUserData(token);
    } else {
      setLoading(false);
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(`${API_BASE_URL}/user_management/api/users/me/`);
      const userData = response.data;
      console.log("userData", userData);
      setUser(userData);
      setUserRole(userData.role);
      setAppAccess(userData.app_access || {}); // Set app access permissions
    } catch (error) {
      console.error('Error fetching user data:', error);
      logoutUser();
    } finally {
      setLoading(false);
    }
  };

  const loginUser = async (username, password) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/api/token/`, { username, password });
      const token = response.data.access;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
      await fetchUserData(token);
    } catch (error) {
      console.error('Login failed:', error);
      throw error;
    }
  };

  const logoutUser = () => {
    setUser(null);
    setUserRole(null);
    setAppAccess({}); // Clear app access permissions
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'];
  };

  return (
    <AuthContext.Provider value={{ user, loading, loginUser, logoutUser, userRole, appAccess }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAuth = () => useContext(AuthContext);

export default AuthContext;